import { useContext } from "react";
import { AppContext } from "../context/app.context";
import { ModelContext } from "../context/model.context";
import { ScheduleContext } from "../context/schedule.context";
import { COLUMN_DAY_ARRAY } from "../utility/keys";
import UnassignedProjects from "../components/UnassignedProjects";
import JobTile from "./schedule/JobTile";
import ScheduleCell from "./schedule/ScheduleCell";
import ScheduleHeader from "./schedule/ScheduleHeader";
import Lock from "../components/Lock";
import LockOpen from "../components/LockOpen";

// NOTE: got help with filtering dates, using start & end values, for overlapping calculations from webpage:
//        https://stackoverflow.com/questions/17304426/javascript-date-range-between-date-range
//              An overlap test is slightly more complex. The following will return true if the two date ranges overlap, regardless of order.

export const OptimizerScreen = ({ children }) => {
  const {
    locationFilter,
    weekDatesArray,
    countsToggle,
    lockAccess_assigned,
    setOpenJobsDetailModal,
    setOpenCrewDetailModal,
    setOpenExtraCellAddModal,
    setOpenExtraCellDetailModal,
    dataFormatting_payrollFields,
  } = useContext(AppContext);
  const { filteredCandidatesList } = useContext(ModelContext);
  const { scheduleArray, countsArray, setDragObject, setDropObject } =
    useContext(ScheduleContext);

  // - - - - - - - - - -

  /**
   * Handles the click event for project details.
   * @param {Object} project - The project object.
   * @param {Event} event - The click event.
   */
  const onProjectDetailClick = (project, event) => {
    if (project.location !== locationFilter) {
      // do not allow for shared installer jobs
      console.log(
        "OptimizerScreen onProjectDetailClick IGNORING, shared installer, location: ",
        project.location
      );
      return;
    }

    event?.stopPropagation();
    // document.getElementById(SCREENS_PROJECTDETAIL_ID).style.display = "block";
    // if (id !== "") {
    //   const projectDetail = scheduleArray.find(
    //     (document) => document.id === id
    //   );
    console.log("OptimizerScreen onProjectDetailClick, project: ", project);
    if (project.extraCell) {
      // extra cell
      setOpenExtraCellDetailModal({
        show: true,
        jobsDetail: [project],
      });
    } else {
      // job
      setOpenJobsDetailModal({ show: true, jobsDetail: [project] });
    }
  };

  // - - - - - - - - - -

  /**
   * Handles the click event for adding an unassigned project.
   */
  const onUnassignedAddClick = () => {
    const projectDetail = scheduleArray.find(
      (document) => document.start === "1/10/2025"
    );
    console.log(
      "OptimizerScreen onUnassignedDetailClick, projectDetail: ",
      projectDetail
    );
    // setOpenUnassignedDetailModal({ show: true });
    setOpenExtraCellAddModal({ show: true, jobsDetail: projectDetail });
  };

  // - - - - - - - - - -

  /**
   * Handles the click event for crew details.
   * @param {string} id - The crew ID.
   */
  const onCrewDetailClick = (id) => {
    const newCrewDetail = filteredCandidatesList.find(
      (document) => document.__ID_Candidate === id
    );
    console.log(
      "OptimizerScreen onCrewDetailClick, id: ",
      id,
      "; payrollNo(atring): ",
      dataFormatting_payrollFields(newCrewDetail.payrollNo),
      "; crewDetail: ",
      newCrewDetail
    );
    setOpenCrewDetailModal({ show: true, crewDetail: newCrewDetail });
  };

  // - - - - - - - - - -

  /**
   * Allows the drop event to occur.
   * @param {Event} event - The drop event.
   */
  const allowDropHere = (event) => {
    event.preventDefault();
  };

  // - - - - - - - - - -

  /**
   * Handles the drag start event.
   * @param {string} id - The ID of the dragged element.
   */
  const onDragStarted = (id) => {
    // event.dataTransfer.setData("div", event.target.id);
    setDragObject(id);
    console.log("OptimizerScreen dragStarted id: ", id);
  };

  // - - - - - - - - - -

  /**
   * Handles the drop event.
   * @param {Event} event - The drop event.
   * @param {string} id - The ID of the drop target.
   */
  const hasBeenDropped = (event, id) => {
    event.preventDefault();
    const droppedObject = { id: id };
    setDropObject(droppedObject);
    console.log(
      "OptimizerScreen hasBeenDropped id: ",
      id,
      ", NO content passed"
    );
  };

  // - - - - - - - - - -

  /**
   * Handles the click event for locking a cell.
   * @param {Event} event - The click event.
   * @param {string} installer - The installer ID.
   * @param {string} date - The date.
   */
  // const onLockClick = (event, installer, date) => {
  //   event.stopPropagation();
  //   if (installer === "column") {
  //     processBatchAttributes("column", "Lock", date);
  //     console.log("OptimizerScreen onLockClick, column lock date: ", date);

  //     //
  //   } else {
  //     // confirm lockAccess
  //     if (
  //       (installer === "Unassig" && lockAccess_unassigned) ||
  //       (installer !== "Unassig" && lockAccess_assigned)
  //     ) {
  //       updateAttributeList(
  //         date,
  //         dataFormatting_payrollFields(installer),
  //         "Lock",
  //         null
  //       );
  //       console.log(
  //         "OptimizerScreen onLockClick, date: ",
  //         date,
  //         ", installer: ",
  //         installer
  //       );
  //     } else {
  //       console.log(
  //         "OptimizerScreen onLockClick, access denied, date: ",
  //         date,
  //         ", installer: ",
  //         installer
  //       );
  //     }
  //   }
  // };

  // - - - - - - - - - -

  /**
   * Checks if a cell is locked.
   * @param {string} payrollNo - The payroll number.
   * @param {number} columnIndex - The column index.
   * @returns {boolean} - True if the cell is locked, false otherwise.
   */
  const isLocked = (payrollNo, columnIndex) => {
    // ignoring COLUMN for now...
    if (payrollNo !== "column") {
      var payrollStr = "";
      if (payrollNo === "") {
        // unassigned cell
        payrollStr = "";
      } else {
        // assigned cell
        payrollStr = dataFormatting_payrollFields(payrollNo);
      }
      // filter for matching date & installer & attribute (not job)
      const currentValueArray = scheduleArray?.filter(
        (document) =>
          payrollStr === document.installer &&
          weekDatesArray[columnIndex] === document.start &&
          document.attribute
      );
      // if found it should have only one match
      const filteredArraySize = currentValueArray?.length;
      // if (payrollStr !== "") {
      //   console.log(
      //     payrollStr,
      //     " isLocked, column: ",
      //     columnIndex,
      //     ", weekDatesArray[columnIndex]: ",
      //     weekDatesArray[columnIndex],
      //     ", filteredArraySize: ",
      //     filteredArraySize,
      //     ", currentValueArray: ",
      //     currentValueArray
      //   );
      // }
      return filteredArraySize > 0 ? currentValueArray[0].locked : false;
    } else {
      return false;
    }
  };

  // - - - - - - - - - -

  /**
   * Renders the date header row.
   * @returns {JSX.Element} - The rendered date header row.
   */
  const renderDateHeaderRow = () => {
    return COLUMN_DAY_ARRAY.map((value, index) => {
      const softJobs = countsArray[index] ? countsArray[index].softJobs : 0;
      const hardJobs = countsArray[index] ? countsArray[index].hardJobs : 0;
      const softSlots = countsArray[index] ? countsArray[index].softSlots : 0;
      const hardSlots = countsArray[index] ? countsArray[index].hardSlots : 0;
      const softFilledSlots = countsArray[index]
        ? countsArray[index].softFilledSlots.size
        : 0;
      const hardFilledSlots = countsArray[index]
        ? countsArray[index].hardFilledSlots.size
        : 0;
      return (
        <ScheduleCell
          key={index}
          className="bg-header [&:nth-child(n+7)]:bg-header-lighter"
        >
          <div
            className="h-full border-b-2
            border-yellow text-sm flex flex-col justify-center items-center px-2"
          >
            {/* <div className="flex justify-between w-full"> */}
            <div className="flex justify-center w-full">
              <span className="flex-auto max-w-4.5"></span>
              <span className="font-medium text-nowrap">
                {value + " " + weekDatesArray[index]}
              </span>
              {lockAccess_assigned ? (
                <button
                  className="fill-primary w-4.5"
                  // onClick={(e) =>
                  //   onLockClick(e, "column", weekDatesArray[index])
                  // }
                >
                  {false ? <Lock></Lock> : <LockOpen></LockOpen>}
                </button>
              ) : (
                <></>
              )}
            </div>
            <div className="flex justify-between w-full">
              <span>
                S:&nbsp;
                <span className="font-bold">
                  {countsToggle ? softFilledSlots : softJobs}
                </span>
                &nbsp;/&nbsp;
                <span className="font-bold">{softSlots}</span>
              </span>
              <span>
                H:&nbsp;
                <span className="font-bold">
                  {countsToggle ? hardFilledSlots : hardJobs}
                </span>
                &nbsp;/&nbsp;
                <span className="font-bold">{hardSlots}</span>
              </span>
            </div>
          </div>
        </ScheduleCell>
      );
    });
  };

  // - - - - - - - - - -

  /**
   * Renders the unassigned scheduled cells for a given column.
   * @param {number} columnIndex - The column index.
   * @returns {JSX.Element[]} - The rendered unassigned scheduled cells.
   */
  const renderUnassignedScheduledCells = (columnIndex) => {
    // added filter for empty encodedTitles to prevent locked cells without titles from displaying an empty string
    return scheduleArray
      .filter(
        (document) =>
          ((new Date(document.start).getTime() >=
            new Date(weekDatesArray[columnIndex]).getTime() &&
            new Date(document.end).getTime() <=
              new Date(weekDatesArray[columnIndex]).getTime()) ||
            (new Date(weekDatesArray[columnIndex]).getTime() >=
              new Date(document.start).getTime() &&
              new Date(weekDatesArray[columnIndex]).getTime() <=
                new Date(document.end).getTime())) &&
          document.installer === ""
      )
      .filter(
        (document) =>
          document.encodedSmallTitle !== "" && document.encodedLargeTitle !== ""
      )
      .map((project) => (
        <JobTile
          key={project.id}
          project={project}
          screenName="optimizer"
          projectDetailClick={onProjectDetailClick}
          dragStarted={onDragStarted}
          className="bg-skin-primary"
        ></JobTile>
      ));
  };

  // - - - - - - - - - -

  /**
   * Renders the unassigned cell.
   * @returns {JSX.Element} - The rendered unassigned cell.
   */
  const renderUnassignedCell = () => {
    return (
      <button
        key={"Unassigned"}
        className="w-full text-dark-grey text-sm font-semibold flex-1 basis-32 "
        onClick={() => onUnassignedAddClick()}
      >
        Unassigned
      </button>
    );
  };

  // - - - - - - - - - -

  /**
   * Renders the crew cell.
   * @param {Object} document - The crew document.
   * @returns {JSX.Element} - The rendered crew cell.
   */
  const renderCrewCell = (document) => {
    const furnitureFlag =
      document.capacity_carpet_once_furniture > 0 ||
      document.capacity_vinyl_once_furniture > 0 ||
      document.capacity_vinylSheet_once_furniture > 0 ||
      document.capacity_tile_once_furniture > 0 ||
      document.capacity_backsplash_once_furniture > 0 ||
      document.capacity_floating_once_furniture > 0 ||
      document.capacity_glueDown_once_furniture > 0 ||
      document.capacity_nailDown_once_furniture > 0
        ? true
        : false;

    const floatingFlag = document.capacity_floating_once > 0 ? true : false;
    const glueDownFlag = document.capacity_glueDown_once > 0 ? true : false;
    const nailDownFlag = document.capacity_nailDown_once > 0 ? true : false;
    const vinylFlag = document.capacity_vinylSheet_once > 0 ? true : false;
    const tileFlag = document.capacity_tile_once > 0 ? true : false;
    const backsplashFlag = document.capacity_backsplash_once > 0 ? true : false;

    // add tooltips to emoji icons
    var tooltipText = "";
    tooltipText = floatingFlag ? `🛟 floating\n` : tooltipText;
    tooltipText = glueDownFlag ? tooltipText + `🖌️ gluedown\n` : tooltipText;
    tooltipText = nailDownFlag ? tooltipText + `🔨 nailDown\n` : tooltipText;
    tooltipText = vinylFlag ? tooltipText + `📄 vinyl\n` : tooltipText;
    tooltipText = tileFlag ? tooltipText + `🪨 tile\n` : tooltipText;
    tooltipText = backsplashFlag
      ? tooltipText + `🌊 backsplash\n`
      : tooltipText;

    const emptyString = "";

    return (
      <span className="block h-full px-2 font-medium">
        <span className="block w-full h-full font-bold tooltip">
          <button
            key={document.__ID_Candidate}
            className="flex items-center justify-center w-full text-black text-sm font-semibold basis-32 whitespace-normal"
            onClick={() => onCrewDetailClick(document.__ID_Candidate)}
          >
            {document.Name_Full}
            <br />
            {dataFormatting_payrollFields(document.payrollNo)}
            <br />
            {emptyString
              .concat("", furnitureFlag ? "🪑" : "")
              .concat("", floatingFlag ? "🛟" : "")
              .concat("", glueDownFlag ? "🖌️" : "")
              .concat("", nailDownFlag ? "🔨" : "")
              .concat("", vinylFlag ? "📄" : "")
              .concat("", tileFlag ? "🪨" : "")
              .concat("", backsplashFlag ? "🌊" : "")}
          </button>
          <span className="tooltiptext">{tooltipText}</span>
        </span>
      </span>
    );
  };

  // - - - - - - - - - -

  /**
   * Renders the assigned scheduled cells for a given column and crew.
   * @param {number} columnIndex - The column index.
   * @param {Object} document - The crew document.
   * @param {boolean} isLockedCell - Whether the cell is locked.
   * @returns {JSX.Element[]} - The rendered assigned scheduled cells.
   */
  const renderAssignedScheduledCells = (
    columnIndex,
    document,
    isLockedCell
  ) => {
    const theCell = scheduleArray
      .filter(
        (project) =>
          project.installer === dataFormatting_payrollFields(document.payrollNo)
      )
      .filter(
        (project) =>
          (new Date(project.start).getTime() >=
            new Date(weekDatesArray[columnIndex]).getTime() &&
            new Date(project.end).getTime() <=
              new Date(weekDatesArray[columnIndex]).getTime()) ||
          (new Date(weekDatesArray[columnIndex]).getTime() >=
            new Date(project.start).getTime() &&
            new Date(weekDatesArray[columnIndex]).getTime() <=
              new Date(project.end).getTime())
      )
      .filter(
        (project) =>
          project.encodedSmallTitle !== "" && project.encodedLargeTitle !== ""
      )
      .map((project) => {
        var theTile = "";
        if (project.location === locationFilter) {
          // 2025-02-14 - return JobTile based on location (shared installer)
          theTile = (
            <JobTile
              key={project.id}
              project={project}
              draggable={!isLockedCell}
              screenName="optimizer"
              projectDetailClick={onProjectDetailClick}
              dragStarted={onDragStarted}
            ></JobTile>
          );
        } else {
          // job is assigned shared installer from another location
          project.encodedSmallTitle = "SHARED " + project.location;
          project.encodedLargeTitle = "SHARED " + project.location;
          theTile = (
            <JobTile
              key={project.id}
              project={project}
              screenName="optimizer"
              draggable={false}
              projectDetailClick={onProjectDetailClick}
            ></JobTile>
          );
          // console.log("theTile: ", theTile);
        }
        return theTile;
      });

    return theCell;
  };

  // - - - - - - - - - -

  return (
    <div className="schedule-page bg-skin-base">
      <div className="w-60 basis-60 shrink-0">
        <UnassignedProjects />
      </div>
      <div className="flex-auto flex flex-col overflow-hidden ml-6 px-4 pt-8">
        <div>
          <ScheduleHeader></ScheduleHeader>
        </div>
        <div className="overflow-auto h-full w-full mt-8 ">
          <div className="sticky top-0 z-10 text-white">
            <div className="flex min-h-[2.875rem]">
              <div
                className="flex justify-center items-center font-semibold bg-header border-b-2 border-yellow min-w-[8.6rem]
                          p-2 flex-1 overflow-hidden basis-32 first:sticky first:left-0"
              >
                <span>Crews</span>
              </div>
              {renderDateHeaderRow()}
            </div>
            <div className="flex min-h-[8rem]">
              <ScheduleCell>
                <div className="bg-crew-surface-unassigned text-dark-grey text-sm font-semibold p-2 text-center h-full">
                  {
                    // this renders a single cell, with button: "Unassigned"
                    renderUnassignedCell()
                  }
                </div>
              </ScheduleCell>
              {
                // this renders all of the unassigned cells, in 1 row only
                COLUMN_DAY_ARRAY.map((value, columnIndex) => (
                  <ScheduleCell key={columnIndex}>
                    <div
                      className="bg-crew-surface-unassigned h-full text-skin-primary flex flex-col"
                      onDrop={(e) => hasBeenDropped(e, "Unassigned_" + value)}
                      onDragOver={(e) =>
                        !isLocked("", columnIndex) &&
                        !isLocked("column", columnIndex) &&
                        allowDropHere(e)
                      }
                    >
                      {renderUnassignedScheduledCells(columnIndex)}
                    </div>
                  </ScheduleCell>
                ))
              }
            </div>
          </div>
          {
            // this renders all of the standard crew & job cells, 1 row at a time
            filteredCandidatesList.map((crew, candidateIndex) => (
              <div className="flex mt-2 min-h-[6.25rem]" key={candidateIndex}>
                <ScheduleCell>
                  <div
                    className={`${
                      crew.Scheduling_Surface === "Soft Surface"
                        ? "bg-crew-surface-soft"
                        : "bg-crew-surface-hard"
                    }   p-2 h-full`}
                  >
                    {renderCrewCell(crew)}
                  </div>
                </ScheduleCell>
                {COLUMN_DAY_ARRAY.map((value, columnIndex) => {
                  const isLockedCell =
                    isLocked(crew.payrollNo, columnIndex) ||
                    isLocked("column", columnIndex);
                  return (
                    <ScheduleCell key={columnIndex}>
                      <div
                        className={`${
                          crew.Scheduling_Surface === "Soft Surface"
                            ? "bg-skin-surface-soft"
                            : "bg-skin-surface-hard"
                        }  h-full flex flex-col`}
                        onDrop={(e) =>
                          hasBeenDropped(
                            e,
                            dataFormatting_payrollFields(crew.payrollNo) +
                              "_" +
                              columnIndex
                          )
                        }
                        onDragOver={(e) => !isLockedCell && allowDropHere(e)}
                      >
                        {renderAssignedScheduledCells(
                          columnIndex,
                          crew,
                          isLockedCell
                        )}
                      </div>
                    </ScheduleCell>
                  );
                })}
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};
