// - - - - - - - - - -

export const MILLISECONDS_PER_DAY = 1000 * 3600 * 24;

// - - - - - - - - - -

// 2025-02-07 - project search reveals this apiKey is never used....
// export const MONGODB_API_KEY = "oKpNMep7LK1eqAIk1szb66GByIxPg8dk2sg6sxaY";
export const REALM_APP_ID = "scheduler-vcuhr"; // e.g. myapp-abcde
export const MONGODB_DATASOURCE = "mongodb-atlas";

// moved to ".env.xxx" and is dynamically loading via Environment Variables
// export const MONGODB_DATABASE_DEV = "UAT";
// export const MONGODB_DATABASE_PROD = "Warehouses";

export const MONGODB_COLLECTION_ACTIVEQUEUE = "ActiveQueue";
export const MONGODB_COLLECTION_CANDIDATES = "Candidates";
export const MONGODB_COLLECTION_CREWS = "Crews";
export const MONGODB_COLLECTION_ATTRIBUTES = "Attributes";
export const MONGODB_COLLECTION_USERS = "Users";

// - - - - - - - - - -

export const FILTERS_SURFACE_CARPET = 0;
export const FILTERS_SURFACE_VINYL = 1;
export const FILTERS_SURFACE_PLANK = 2;
export const FILTERS_SURFACE_TILE = 3;
export const FILTERS_SURFACE_WOOD = 4;
export const FILTERS_SURFACE_BACKSPLASH = 5;
export const FILTERS_SURFACE_UNKNOWN = 6;
export const FILTERS_SURFACE_DETAIL = 7;

// - - - - - - - - - -

export const FILTERS_CLASSIFICATION_DETAIL = 0;
export const FILTERS_CLASSIFICATION_JOB = 1;
export const FILTERS_CLASSIFICATION_RELATED_PO = 2;
export const FILTERS_CLASSIFICATION_WORKORDER_EXPENSE = 3;
export const FILTERS_CLASSIFICATION_REPAIR_ORDER = 4;
export const FILTERS_CLASSIFICATION_PAID_IN_ERROR = 5;
export const FILTERS_CLASSIFICATION_OTHER = 6;

// - - - - - - - - - -

export const FILTERS_CREW_DETAILER = 0;
export const FILTERS_CREW_INSTALLER = 1;
export const FILTERS_CREW_HELPER = 2;
export const FILTERS_CREW_EMPLOYEE = 3;

// - - - - - - - - - -

export const FILTERS_CREW_SURFACE_SOFT = 0;
export const FILTERS_CREW_SURFACE_HARD = 1;

// - - - - - - - - - -

export const SCREENS_PROJECTDETAIL_ID = "id01";
export const SCREENS_CANDIDATEDETAIL_ID = "id02";
export const SCREENS_FILTERS_ID = "id03";
export const SCREENS_EMPTYCELLOPTIONS_ID = "id04";

// - - - - - - - - - -

export const FILTERS_SURFACE_ARRAY = [
  { name: "Carpet" },
  { name: "Vinyl" },
  { name: "Plank" },
  { name: "Tile" },
  { name: "Wood" },
  { name: "Backsplash" },
  { name: "UNK" },
];
export const FILTERS_CLASSIFICATION_ARRAY = [
  { name: "Detail" },
  { name: "Job" },
  { name: "Related PO" },
  { name: "Workorder/Expense" },
  { name: "Repair Order" },
  { name: "Paid In Error" },
  { name: "Other" },
];
export const FILTERS_CREW_ARRAY = [
  { name: "Detailer" },
  { name: "Installer" },
  { name: "Helper" },
  { name: "Employee" },
];
export const FILTERS_CREW_SURFACE_ARRAY = [
  { name: "Soft Surface" },
  { name: "Hard Surface" },
];

// - - - - - - - - - -

// these are in the same order and matching Recruiter Capacities tab
export const CAPACITIES_SURFACE_ARRAY = [
  { name: "Carpet" },
  { name: "VinylPlank" },
  { name: "VinylSheet" },
  { name: "Tile" },
  { name: "Backsplash" },
  { name: "Floating" },
  { name: "GlueDown" },
  { name: "NailDown" },
];

// - - - - - - - - - -

export const JOB_FURNITURE_SKULIST = [
  503239, 503276, 503273, 503252, 503266, 900001, 900008, 900017, 900022,
  900036, 1101360, 1101361, 1101362, 1101363, 253313,
];

// - - - - - - - - - -

export const JOB_FLOATING_SKULIST = [
  503270, 1060634, 724007, 503247, 712505, 188400, 894131, 724006, 1060635,
  1243934,
];

// - - - - - - - - - -

export const JOB_GLUEDOWN_SKULIST = [1051453, 712504, 188208];

// - - - - - - - - - -

export const JOB_NAILDOWN_SKULIST = [712217, 188204];

// - - - - - - - - - -

export const JOB_TILE_SKULIST = [104730, 227597];

// - - - - - - - - - -

export const JOB_BACKSPLASH_SKULIST = [475359, 475358, 475356];

// - - - - - - - - - -

export const JOB_SHEETVINYL_SKULIST = [163973];

// ------------------

export const COLUMN_DAY_ARRAY = [
  "Mon",
  "Tue",
  "Wed",
  "Thr",
  "Fri",
  "Sat",
  "Sun",
];

// ------------------

export const JOB_INFO_CHECKBOXES = [
  { name: "Flags", field: "flags", selected: true },
  { name: "Customer Last Name", field: "lastName", selected: true },
  { name: "Qty", field: "qty", selected: true },
  { name: "Store #", field: "storeNo", selected: true },
  { name: "Arrival", field: "encodedArrival", selected: true },
  { name: "Classification", field: "classification", selected: true },
  { name: "Invoice #", field: "invoiceNo", selected: false },
  { name: "Project #", field: "projectNo", selected: false },
  { name: "Status", field: "statusCode", selected: false },
];

// ------------------

export const DOCUMENT_ATTRIBUTES_TEMPLATE = {
  companyCode: "",
  location: "",
  _kf_payrollNo: "",
  date: "",
  id: "",
  invoiceNo: 0,
  encodedLargeTitle: "",
  encodedSmallTitle: "",
  locked: false,
  distant: false,
  expedite: false,
};
