import React, { useContext, useEffect, useState } from "react";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import { AppContext } from "../../context/app.context";
import { ModelContext } from "../../context/model.context";
import WeekPicker from "../../components/WeekPicker";
import { COLUMN_DAY_ARRAY } from "../../utility/keys";
import TextArea from "../../components/TextArea";

function UnassignedDetailModal({ setOpenUnassignedDetailModal }) {
  const { weekDatesArray, onWeekToday, onWeekBehind, onWeekAhead } =
    useContext(AppContext);
  const { updateUnassignedAttributeList } = useContext(ModelContext);
  const [customValues, setCustomValues] = useState(Array(7).fill(""));

  // -------

  const getCustomValues = (dayIndex) => {
    return customValues[dayIndex];
  };

  // -------

  const onChangeStatus = (customText = "", dayIndex) => {
    const date = new Date(weekDatesArray[dayIndex]).toLocaleDateString("en-us");
    const prevCustomText = getCustomValues(dayIndex);
    if (prevCustomText !== customText) {
      customValues[dayIndex] = customText;
      setCustomValues([...customValues]);
      updateUnassignedAttributeList(date, customText);
    }
  };

  // ------

  return (
    <Modal onClose={() => setOpenUnassignedDetailModal({ show: false })}>
      <Modal.Header>Custom Note for specified date this week</Modal.Header>
      <Modal.Content>
        <div className="flex flex-col gap-4 w-[984px]">
          <div className="pb-4 ">
            <div className="flex flex-col gap-4">
              <div className="flex gap-4 h-6">
                <Button onClick={() => onWeekToday()}>Today</Button>
                <WeekPicker
                  value={weekDatesArray[0]}
                  weekBehind={() => onWeekBehind()}
                  weekAhead={() => onWeekAhead()}
                ></WeekPicker>
              </div>
              <div className="flex gap-2">
                {COLUMN_DAY_ARRAY.map((day, dayIndex) => (
                  <div className="flex-1" key={dayIndex}>
                    <div
                      className={`${
                        dayIndex < 5 ? "bg-header" : "bg-header-lighter"
                      }
                      text-white border-b-2 border-b-yellow flex justify-center py-2`}
                    >
                      {day}&nbsp;{weekDatesArray[dayIndex]}
                    </div>
                    <div className="flex flex-col gap-1 p-2 bg-skin-surface-hard">
                      <div>
                        <TextArea
                          placeholder="Custom Notes"
                          rows={10}
                          value={getCustomValues(dayIndex)}
                          onChange={(e) =>
                            onChangeStatus(e.target.value, dayIndex)
                          }
                          onBlur={(e) =>
                            onChangeStatus(e.target.value, dayIndex)
                          }
                          className="bg-skin-base border border-black border-opacity-20 "
                        ></TextArea>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <div className="h-8 flex">
          <Button
            color="light"
            className="uppercase w-31"
            onClick={() => setOpenUnassignedDetailModal({ show: false })}
          >
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default UnassignedDetailModal;
