import { createContext, useContext, useState, useEffect } from "react";
import LogRocket from "logrocket";
import * as Realm from "realm-web";
import { useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import {
  REALM_APP_ID,
  MONGODB_DATASOURCE,
  MONGODB_COLLECTION_ACTIVEQUEUE,
  MONGODB_COLLECTION_CANDIDATES,
  MONGODB_COLLECTION_ATTRIBUTES,
  MONGODB_COLLECTION_USERS,
  FILTERS_SURFACE_DETAIL,
  FILTERS_SURFACE_CARPET,
  FILTERS_SURFACE_WOOD,
  FILTERS_SURFACE_VINYL,
  FILTERS_SURFACE_TILE,
  FILTERS_SURFACE_BACKSPLASH,
  FILTERS_SURFACE_UNKNOWN,
  FILTERS_CLASSIFICATION_DETAIL,
  FILTERS_CLASSIFICATION_JOB,
  FILTERS_CLASSIFICATION_RELATED_PO,
  FILTERS_CLASSIFICATION_WORKORDER_EXPENSE,
  FILTERS_CLASSIFICATION_REPAIR_ORDER,
  FILTERS_CLASSIFICATION_PAID_IN_ERROR,
  FILTERS_CLASSIFICATION_OTHER,
  FILTERS_CREW_DETAILER,
  FILTERS_CREW_INSTALLER,
  FILTERS_CREW_HELPER,
  FILTERS_CREW_EMPLOYEE,
  FILTERS_CREW_SURFACE_SOFT,
  FILTERS_CREW_SURFACE_HARD,
  DOCUMENT_ATTRIBUTES_TEMPLATE,
  FILTERS_SURFACE_PLANK,
} from "../utility/keys";
import {
  AppContext,
  defaultJobTitleConfig,
  defaultScreenAccessList,
} from "./app.context";

// - - - - - - - - - - - - - - - - - - - -

//SAMPLE from Warehouses::ActiveQueue
// {
//   "_id": {
//     "$oid": "6772e24717e092e1acd88a28"
//   },
//   "InvoiceLineItems": [
//     {
//       "KI_AQ » InvoiceLineItems::qty": 162,
//       "KI_AQ » InvoiceLineItems::sku": "419901",
//       "KI_AQ » InvoiceLineItems::type": "Carpet"
//     }
//   ],
//   "KellerInteriors::_autoGeneratedModificationTimestamp": "12/30/2024 13:48:30",
//   "Materials": [
//     {
//       "KI_AQ » Materials::qty": 324,
//       "KI_AQ » Materials::total_sqft": 324,
//       "KI_AQ » Materials::total_yds": 36,
//       "KI_AQ » Materials::type": "Carpet"
//     },
//     {
//       "KI_AQ » Materials::qty": 0,
//       "KI_AQ » Materials::total_sqft": 228,
//       "KI_AQ » Materials::total_yds": 25.3333333333333,
//       "KI_AQ » Materials::type": "Pad"
//     }
//   ],
//   "_creationAcct": "iRobot_Venus",
//   "_creationTS": "12/30/2024 13:04:54",
//   "_kf_KellerID": "ID-1971864",
//   "_modificationTS_invoiceRecord": "01/29/2025 07:41:01",
//   "_modifitionAcct": "elianaluiscruz",
//   "_modifitionTS": "01/29/2025 11:59:31",
//   "arrival_end": "?",
//   "arrival_time_text": "8 AM - 11 AM",
//   "classification": "Job",
//   "collection": "ActiveQueue",
//   "communication_latestSent_TS": "12/30/2024 13:05:22",
//   "communication_responses": "",
//   "companyCode": "KI",
//   "customerPortal_Scheduling": "1",
//   "customer_address": "107 Wading Bird Loop , Blythewood, SC 29016",
//   "customer_cellPhone": "803-381-7351",
//   "customer_firstName": "Matthew",
//   "customer_lastName": "Jacobs",
//   "date_arranged": "01/29/2025",
//   "date_billed": "",
//   "date_estimatedCompletion": "02/07/2025",
//   "date_installed": "",
//   "date_scheduled": "02/07/2025",
//   "expressShipFlag": "",
//   "id": "32A7F9CB-AAFC-C84A-A70D-684DCF5BA630",
//   "info": "",
//   "installer": "",
//   "invoiceNo": 2189551,
//   "invoiceUUID": "261DD3F9-920E-DB4C-B2F5-385B2FC49EA5",
//   "location": "COL",
//   "lowes_projectNo": "214078242",
//   "lowes_soldDate": "12/30/2024",
//   "lowes_storeNo": "3026",
//   "mongo_id": "6772e24717e092e1acd88a28",
//   "portalState": 100,
//   "scheduleDate_Lock_Flag": "",
//   "schedulingAttributes": "",
//   "statusCode": 4,
//   "statusCode_today": 3.1,
//   "surface": "Carpet",
//   "totalOwed": ""
// }

//SAMPLES from Warehouses::Attributes
//
// DAILY ATTRIBUTE
// {
//   "_id": {
//     "$oid": "673f417358be0e95dbef0dfa"
//   },
//   "companyCode": "KI",
//   "date": "2/2/2025",
//   "_kf_payrollNo": "3519.01",
//   "location": "HUN",
//   "id": "74ea9382-6b64-44bc-8343-0c0ece3ddc38",
//   "encodedSmallTitle": "OFF",
//   "encodedLargeTitle": "OFF",
//   "distant": false,
//   "expedite": false,
//   "locked": false,
//   "invoiceNo": ""
// }
//
// JOB ATTRIBUTE
// {
//   "_id": {
//     "$oid": "679d25728198670a3c9b69b3"
//   },
//   "companyCode": "KI",
//   "location": "HUN",
//   "_kf_payrollNo": "",
//   "date": "2/21/2025",
//   "id": "1b6b6f1c-7d52-4432-8689-a85d5eff790a",
//   "invoiceNo": 2198895,
//   "encodedLargeTitle": "",
//   "encodedSmallTitle": "",
//   "locked": false,
//   "distant": false,
//   "expedite": false,
//   "availability": null,
//   "deleted": null,
//   "extra": null
// }
//
// EXTRA CELL
// {
//   "_id": {
//     "$oid": "679d4735af51ab5bbf9f262b"
//   },
//   "companyCode": "KI",
//   "location": "ATL",
//   "_kf_payrollNo": "",
//   "date": "12/25/2025",
//   "id": "9dc41c27-11bc-4208-a1ae-a563cb977bd4",
//   "invoiceNo": 0,
//   "encodedLargeTitle": "",
//   "encodedSmallTitle": "",
//   "locked": false,
//   "distant": false,
//   "expedite": false,
//   "extra": {
//     "surface": "Carpet",
//     "customText": "CHRISTMAS DAY"
//   }
// }
//
// DEFAULTS ATTRIBUTE
// {
//   "_id": {
//     "$oid": "677d974a1bfd7babb240d910"
//   },
//   "companyCode": "KI",
//   "location": "OCO",
//   "_kf_payrollNo": "2850.01",
//   "date": "",
//   "id": "b2a84a08-a75b-4234-8367-7284625fe4ab",
//   "invoiceNo": 0,
//   "encodedLargeTitle": "",
//   "encodedSmallTitle": "",
//   "locked": false,
//   "distant": false,
//   "expedite": false,
//   "availability": [
//     {
//       "Sat": "OFF"
//     },
//     {
//       "Sun": "OFF"
//     }
//   ]
// }

//SAMPLE from Warehouses::Candidates
// {
//   "_id": {
//     "$oid": "677ec5be61378545b5fdb796"
//   },
//   "Acknowledgement_Email_Date": "12/16/2021",
//   "Additional_Locations": "",
//   "Associated_Installer_Candidate_ID": "",
//   "Associated_Installer_Full_Name": "",
//   "Associated_Installer_Payroll_ID": "",
//   "AttachmentPortalOption": "all",
//   "AttachmentPortalSearch": "",
//   "AttachmentTypeIdSelected": "",
//   "AttachmentVirtualListRowCount": "",
//   "BACK_Notes": "",
//   "Background_Check": "",
//   "Background_Check_Verdict": "Pass",
//   "Background_FeePaid": "",
//   "Background_Form_Received_Date": "05/18/2023",
//   "Background_Form_Sent_Date": "12/16/2021",
//   "Badge_Photo_Date": "12/16/2021",
//   "Badge_Photo_Link": "https://ca.fadv.com/CA/DigitalBadge/dbId/dc4ab88a-758c-47f4-b40f-9f9124f62b45",
//   "Badge_Sent_Date": "",
//   "Business_Unit": "",
//   "Calc_Detailer_Payroll_Name": "",
//   "Calc_Installer_Payroll_Name": "3146.01-Robson Silva",
//   "Calc_List_Staff_Id": "",
//   "Calc_Name_First_Initial": "R",
//   "Calc_Name_Full": "Robson Silva",
//   "Calc_Name_Last_Initial": "S",
//   "Calc_Name_iPhone_List_View": "Silva, Robson",
//   "Calc_SkillsList": "Vinyl, Laminate, Gluedown Wood, Naildown Wood, Vinyl (Floating)",
//   "Calc_city_State": "Blythewood, SC",
//   "Candidate_Notes": "",
//   "Category": "",
//   "Cell_Phone": "704-877-7628",
//   "Certification_Firm": "",
//   "Certification_Renovator": "",
//   "City": "Blythewood",
//   "Country": "",
//   "Current_Date_Started": "",
//   "Current_Employer": "",
//   "Current_Industry": "",
//   "Current_Location": "",
//   "Current_Position": "",
//   "Current_Salary": "",
//   "Current_Title": "",
//   "Dashboard_Status": "New Submission",
//   "Dashboard_Status_Code": 70,
//   "Dashboard_Status_Code_Suppressed": "",
//   "Dashboard_Status_Date": "01/12/2022",
//   "Dashboard_Status_Updated_By": "steruskyp",
//   "Date_Last_Worked": "",
//   "Date_Started": "",
//   "Date_Until_Unsuppress": "",
//   "Date_When_Suppressed": "",
//   "Days_In_Status": 1105,
//   "Degree": "",
//   "Department": "",
//   "Direct_Deposit_Form_Date": "12/28/2021",
//   "DoingBusinessAs": "Excellent Home Improvement LLC",
//   "EPA_Form_Date": "",
//   "EPA_PayrollNo": "",
//   "EPA_State": "",
//   "E_Signature_Form_Date": "12/29/2021",
//   "Education_notes": "",
//   "Email": "robsonsilva1006@gmail.com",
//   "Email_c": "",
//   "EmployeeType": "Contractor",
//   "Employer": "",
//   "FX_Comments": "I also do stairs and any kind of repairs.",
//   "FX_LiabilityInsurance": "Yes",
//   "FX_Obtain": "",
//   "FX_WorkersComp": "Yes",
//   "FX_encodedLowesStoreNo_List": "",
//   "Fax": "",
//   "Find": "Robson  Silva  Blythewood SC 29016",
//   "Hiring_Manager": "Carlos Collazo",
//   "INT_Notes": "",
//   "In_Person_Interview_Date": "",
//   "Industry": "",
//   "Installer": "",
//   "Installer_Agreement_Form_Date": "12/29/2021",
//   "Introduced_By": "",
//   "Job_Grade": "",
//   "LLC_Flag": "1",
//   "LaborAttributes": "Cut Doors\rDifficult / Gate Access\rFurniture/Appliances\rHard Surface\rHard Surface Removal\rInstall Molding\rSoft Surface Removal\rWood Pattern/Design\rWood Undercut Fireplace\rHard Surface Flooring\rCali Bamboo\rWood Stairs\rLaminate Stairs",
//   "Labor_Percentage": "",
//   "Labor_Tier": "Sub",
//   "Location": "Columbia, SC",
//   "LowesStoreNo_List": "",
//   "LowesTeamMemberId": "TM00059149",
//   "Name_First": "Robson ",
//   "Name_Full": "Robson  Silva",
//   "Name_Industry": "",
//   "Name_Last": "Silva",
//   "OTE": "",
//   "OTE_Notes": "",
//   "PayrollNo_Link_Sent_Date": "12/28/2021",
//   "Phone1": "",
//   "Phone2": "",
//   "Phone_Interview_Date": "",
//   "Portal_Password": "06221988",
//   "Portal_Password_Dashboard_Display": "06221988",
//   "Portal_Status": "Active",
//   "Portal_Username": "silvar",
//   "Portal_Username_Dashboard_Display": "silvar",
//   "Position_Current": "",
//   "Postal_Code": "29016",
//   "Preferences_Workweek": "Sunday\rSaturday",
//   "Prospect_Reject_Note": "",
//   "RecordType": "Installer",
//   "RecordTypeDisplay": "Installer",
//   "RecordTypeEmployeeContractor": "Contractor",
//   "Recruiter": "Carlos Collazo",
//   "Resume": "",
//   "Salary_Current": "",
//   "Scheduling_Surface": "Hard Surface",
//   "ShortName_Initials": "RDS",
//   "Skill_Notes": "",
//   "Skills": "Vinyl\rLaminate\rGluedown Wood\rNaildown Wood\rVinyl (Floating)",
//   "Source": "",
//   "Sourcer": "Web Application",
//   "Staff_Name_G": "",
//   "Stages": "Active",
//   "Stages_old": "",
//   "State": "SC",
//   "Status": "",
//   "Status_Date": "12/13/2021",
//   "Street1": "605 Coyote Ln",
//   "Street2": "",
//   "Subcontractor_Form_Received_Date": "05/18/2023",
//   "Subcontractor_Form_Sent_Date": "12/16/2021",
//   "University": "",
//   "Vanity_Title": "",
//   "W9_Form_Received_Date": "12/23/2021",
//   "W9_Form_Sent_Date": "12/16/2021",
//   "Website": "",
//   "YTD_Deductions": 0,
//   "YTD_Earnings": 0,
//   "YTD_NetPay": 0,
//   "YTD_PayDate": "",
//   "YearsInTrade": "7 years",
//   "_DEPRECATED_Photos_Status_Dashboard": "",
//   "__ID_Candidate": "CAND_31994",
//   "_cActive": "Active",
//   "_cRecordAccess": 1,
//   "_cRecordID": 33115,
//   "_creationAccount": "FX",
//   "_creationTS": "12/13/2021 11:48:58",
//   "_id_manager": "",
//   "_id_recruiter": "",
//   "_id_requisition": "",
//   "_id_selected_action": "",
//   "_id_sourcer": "",
//   "_modificationAccount": "davidgunter",
//   "_modificationTS": "01/20/2025 08:11:06",
//   "_modificationTS_old": "",
//   "abm_redemptionCode_ki": "",
//   "cActiveFlag": 1,
//   "cActiveFlag_OLD": 1,
//   "cAllPhones": "",
//   "cBusinessName": "Excellent Home Improvement LLC",
//   "cComplianceFollowup": 0,
//   "cCurrentUser": "davidgunter",
//   "cLocationCode": "COL",
//   "cMobileApp_version_current": 2,
//   "cMobileApp_version_minimum": 2,
//   "cPayrollNo_LeadInstaller": 3146.01,
//   "cPayrollNo_prefix": 3146,
//   "cPayrollNo_suffix": 0.01,
//   "cSignatureFilename": "installerSig.png",
//   "cSkillsDashboard_Display": "Vinyl, Hardwood",
//   "cStatic1": 1,
//   "cStreetCombined": "605 Coyote Ln ",
//   "capacity_backsplash_once": 0,
//   "capacity_backsplash_once_furniture": 0,
//   "capacity_backsplash_once_steps": 0,
//   "capacity_backsplash_prime_secondary": "None",
//   "capacity_backsplash_twice": 0,
//   "capacity_backsplash_twice_furniture": 0,
//   "capacity_backsplash_twice_steps": 0,
//   "capacity_carpet_once": 0,
//   "capacity_carpet_once_furniture": 0,
//   "capacity_carpet_once_steps": 0,
//   "capacity_carpet_prime_secondary": "None",
//   "capacity_carpet_twice": 0,
//   "capacity_carpet_twice_furniture": 0,
//   "capacity_carpet_twice_steps": 0,
//   "capacity_floating_once": 500,
//   "capacity_floating_once_furniture": 4,
//   "capacity_floating_once_steps": 15,
//   "capacity_floating_prime_secondary": "Primary",
//   "capacity_floating_twice": 0,
//   "capacity_floating_twice_furniture": 0,
//   "capacity_floating_twice_steps": 0,
//   "capacity_glueDown_once": 250,
//   "capacity_glueDown_once_furniture": 3,
//   "capacity_glueDown_once_steps": 15,
//   "capacity_glueDown_prime_secondary": "Primary",
//   "capacity_glueDown_twice": 0,
//   "capacity_glueDown_twice_furniture": 0,
//   "capacity_glueDown_twice_steps": 0,
//   "capacity_nailDown_once": 400,
//   "capacity_nailDown_once_furniture": 3,
//   "capacity_nailDown_once_steps": 15,
//   "capacity_nailDown_prime_secondary": "Primary",
//   "capacity_nailDown_twice": 0,
//   "capacity_nailDown_twice_furniture": 0,
//   "capacity_nailDown_twice_steps": 0,
//   "capacity_tile_once": 0,
//   "capacity_tile_once_furniture": 0,
//   "capacity_tile_once_steps": 0,
//   "capacity_tile_prime_secondary": "None",
//   "capacity_tile_twice": 0,
//   "capacity_tile_twice_furniture": 0,
//   "capacity_tile_twice_steps": 0,
//   "capacity_vinylSheet_once": 0,
//   "capacity_vinylSheet_once_furniture": 0,
//   "capacity_vinylSheet_once_steps": 0,
//   "capacity_vinylSheet_prime_secondary": "None",
//   "capacity_vinylSheet_twice": 0,
//   "capacity_vinylSheet_twice_furniture": 0,
//   "capacity_vinylSheet_twice_steps": 0,
//   "capacity_vinyl_once": 500,
//   "capacity_vinyl_once_furniture": 4,
//   "capacity_vinyl_once_steps": 15,
//   "capacity_vinyl_prime_secondary": "Primary",
//   "capacity_vinyl_twice": 0,
//   "capacity_vinyl_twice_furniture": 0,
//   "capacity_vinyl_twice_steps": 0,
//   "check_Box_1": "",
//   "collection": "Candidates",
//   "companyCode": "KI",
//   "expirationDate_BusinessLicense": "",
//   "expirationDate_Compliance": "11/30/2025",
//   "expirationDate_EPA_Course_Certification": "",
//   "expirationDate_GeneralLiability": "10/19/2025",
//   "expirationDate_General_Contractor": "",
//   "expirationDate_Home_Improvement": "",
//   "expirationDate_Other": "",
//   "expirationDate_Residential": "",
//   "expirationDate_TradeLicense": "",
//   "expirationDate_WorkersCompensation": "10/19/2025",
//   "exportedToFlooringConnect": 0,
//   "flag_filled_by_us": "",
//   "floorsoftNo": "",
//   "lowesBadgeID": "10145",
//   "lowesLicenseNumberGeneralContractor": "",
//   "lowesLicenseNumberHomeImprovement": "",
//   "lowesLicenseNumberOther": "",
//   "lowesLicenseNumberResidential": "",
//   "lowesLicenseOther": "",
//   "lowesLicenseState": "",
//   "lowesLicenseType": "",
//   "lowesMarketingOptIn": "",
//   "mobileAppDevice": "Apple / iOS",
//   "mongodb_id": "677ec5be61378545b5fdb796",
//   "payrollNo": 3146.01,
//   "payrollNoMain": "",
//   "possible_position": "",
//   "primaryMethodOfContact": "",
//   "schedulingName": "",
//   "yes": ""
// }

//SAMPLE from Warehouses::Users
// {
//   "_id": {
//     "$oid": "679d5162684273d3f69ca4eb"
//   },
//   "companyList": [
//     "KI"
//   ],
//   "defaultCompany": "KI",
//   "defaultLocation": "CHS",
//   "defaultUImode": "dark",
//   "defaultCountsToggle": false,
//   "defaultFilters": {
//     "surfaceFilters": [
//       true,
//       true,
//       true,
//       true,
//       true,
//       true,
//       true
//     ],
//     "classificationFilters": [
//       false,
//       true,
//       true,
//       true,
//       true,
//       false,
//       false
//     ],
//     "crewFilters": [
//       false,
//       true,
//       true,
//       true
//     ],
//     "crewSurfaceFilters": [
//       true,
//       true
//     ]
//   },
//   "locationList": [],
//   "roleList": [
//     "coord_all"
//   ],
//   "screenAccessList": [
//     "optimizer",
//     "scheduler",
//     "jobList"
//   ],
//   "jobTitleConfig": [
//     {
//       "name": "Flags",
//       "field": "flags",
//       "selected": true
//     },
//     {
//       "name": "Customer Last Name",
//       "field": "lastName",
//       "selected": true
//     },
//     {
//       "name": "Qty",
//       "field": "qty",
//       "selected": true
//     },
//     {
//       "name": "Store #",
//       "field": "storeNo",
//       "selected": true
//     },
//     {
//       "name": "Arrival",
//       "field": "encodedArrival",
//       "selected": true
//     },
//     {
//       "name": "Classification",
//       "field": "classification",
//       "selected": true
//     },
//     {
//       "name": "Status",
//       "field": "statusCode",
//       "selected": false
//     },
//     {
//       "name": "Invoice #",
//       "field": "invoiceNo",
//       "selected": false
//     },
//     {
//       "name": "Project #",
//       "field": "projectNo",
//       "selected": false
//     }
//   ],
//   "id": "679d51620a3138eec5356c7e",
//   "username": "jesselstarkey@kellerinteriors.com"
// }

// - - - - - - - - - - - - - - - - - - - -

export const ModelContext = createContext();

// - - - - - - - - - -

export const ModelContextProvider = ({ children }) => {
  const {
    appEnvirons,
    companyCode,
    locationFilter,
    whichList,
    defaultUImode,
    defaultStartDate,
    defaultTab,
    defaultCompanyCode,
    defaultLocationFilter,
    defaultFilters,
    darkMode,
    defaultNewUserRecord,
    jobTitleConfig,
    countsToggle,
    currentFilters,
    weekDatesArray,
    createUuid,
    dataFormatting_payrollFields,
    setDarkMode,
    setJobTitleConfig,
    setCompanyCode,
    setLocationFilter,
    setWhichList,
    setScreenAccess,
    setLocationArray,
    setLockAccess_unassigned,
    setLockAccess_assigned,
    setCurrentFilters,
    setMondayThisWeek,
    setLatestChangeTS,
  } = useContext(AppContext);

  const [mongoApp, setMongoApp] = useState(new Realm.App({ id: REALM_APP_ID }));
  const { instance } = useMsal();
  // const [mongoUser, setMongoUser] = useState(mongoApp.currentUser);
  // mongoDatabase used to be stored in "keys.js" but now in ".env.xxx"; state= appEnvirons from appContext
  const [mongoDatabase, setMongoDatabase] = useState(appEnvirons.mongoDb);
  const [mongoUser, setMongoUser] = useState(null);
  const [events, setEvents] = useState([]);
  const [projectsList, setProjectsList] = useState([]);
  const [candidatesList, setCandidatesList] = useState([]);
  const [requestedCandidatesList, setRequestedCandidatesList] = useState([]);
  const [requestedProjectsList, setRequestedProjectsList] = useState([]);
  const [attributesList, setAttributesList] = useState([]);
  // const [crewsList, setCrewsList] = useState([]);
  const [userDocument, setUserDocument] = useState([]);
  const [filteredProjectsList, setFilteredProjectsList] = useState([]);
  const [filteredCandidatesList, setFilteredCandidatesList] = useState([]);
  const [newAttributeList, setNewAttributeList] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(false);
  const [candidatesLoading, setCandidatesLoading] = useState(false);
  const [crewsLoading, setCrewsLoading] = useState(false);
  const [attributesLoading, setAttributesLoading] = useState(false);
  const [locationsLoading, setLocationsLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [crewQueryList, setCrewQueryList] = useState([]);
  const [projectQueryList, setProjectQueryList] = useState([]);
  const [crewQueryLoading, setCrewQueryLoading] = useState(false);
  const [projectQueryLoading, setProjectQueryLoading] = useState(false);
  const [sharedDefaultsHandlerFlag, setSharedDefaultsHandlerFlag] =
    useState(false);
  const [networkErrorFlag, setNetworkErrorFlag] = useState(false);

  const {
    BSON: { ObjectId },
  } = Realm;

  // - - - - - - - - - -

  /**
   * Sorts an array of objects by a specified field.
   * @param {Array} incomingArray - The array to sort.
   * @param {string} sortFieldPath - The field path to sort by.
   * @param {string} type - The type of the field (Date/Timestamp, Number, String/Text).
   * @param {string} order - The order to sort (ascending, descending).
   * @returns {Array} - The sorted array.
   */
  const myJsonArraySort = (incomingArray, sortFieldPath, type, order) => {
    // from https://stackoverflow.com/questions/979256/sorting-an-array-of-objects-by-property-values
    //
    // - - - - -

    function getDescendantProp(obj, desc) {
      var arr = desc.split(".");
      while (arr.length && (obj = obj[arr.shift()]));
      return obj;
      // from https://stackoverflow.com/questions/8051975/access-object-child-properties-using-a-dot-notation-string
    }

    // - - - - -

    // var incomingArray = JSON.parse(theArray);
    var sortedData = [];
    if (type === "Date/Timestamp") {
      if (order === "ascending") {
        sortedData = incomingArray.sort(function (a, b) {
          return (
            new Date(getDescendantProp(a, sortFieldPath)) -
            new Date(getDescendantProp(b, sortFieldPath))
          );
        });
      } else {
        sortedData = incomingArray.sort(function (a, b) {
          return (
            new Date(getDescendantProp(b, sortFieldPath)) -
            new Date(getDescendantProp(a, sortFieldPath))
          );
        });
      }
    } else if (type === "Number") {
      if (order === "ascending") {
        sortedData = incomingArray.sort(function (a, b) {
          return (
            Number(getDescendantProp(a, sortFieldPath)) -
            Number(getDescendantProp(b, sortFieldPath))
          );
        });
      } else {
        sortedData = incomingArray.sort(function (a, b) {
          return (
            Number(getDescendantProp(b, sortFieldPath)) -
            Number(getDescendantProp(a, sortFieldPath))
          );
        });
      }
    } else if (type === "String/Text") {
      // example of this style of ascending uppercase sorting - [ A, a, B, b, C, c ]
      if (order === "ascending") {
        sortedData = incomingArray.sort(function (a, b) {
          return getDescendantProp(a, sortFieldPath).localeCompare(
            getDescendantProp(b, sortFieldPath),
            undefined,
            { caseFirst: "upper" }
          );
        });
      } else {
        sortedData = incomingArray.sort(function (a, b) {
          return getDescendantProp(b, sortFieldPath).localeCompare(
            getDescendantProp(a, sortFieldPath),
            undefined,
            { caseFirst: "upper" }
          );
        });
      }
    }

    // console.log("ModelContext (" + mongoDatabase + ") myJsonArraySort sortedArray: ", sortedData);
    return sortedData;
  };

  // - - - - - - - - - -

  /**
   * Processes batch attributes for a specified type and value.
   * @param {string} type - The type of batch processing (e.g., "column").
   * @param {string} value - The value to process (e.g., "Lock").
   * @param {string} startDate - The start date for processing.
   */
  const processBatchAttributes = (type, value, startDate) => {
    // function is used for batch locking (attributes) of entire day column
    switch (type) {
      case "column":
        if (value === "Lock") {
          // loop thru crewList
          // NOTE: due to large quantities of Attribute Documents being created
          //       in a short period of time, and each one generating a new
          //       changeStream INSERT/UPDATE, and each one also updating
          //       the mongo _id, there was overlapping conflicts causing
          //       instable UI state until Refresh, so I cheated and added
          //       a 0.1sec delay between each and this alleviated the issue
          // see: https://stackoverflow.com/questions/5226285/settimeout-in-for-loop-does-not-print-consecutive-values
          for (var index = 0; index < candidatesList.length; index++) {
            (function (index) {
              setTimeout(function () {
                const candidate = candidatesList[index];
                const currentPayrollNo = candidate.payrollNo;
                updateAttributeList(
                  startDate,
                  dataFormatting_payrollFields(currentPayrollNo),
                  "Lock",
                  null
                );
              }, index * 100);
            })(index);
          }
        } else {
          // future values
        }
        break;

      default:
        console.log(
          `ModelContext (${mongoDatabase}) processBatchAttributes (${type}) INVALID Case...`
        );
        break;
    }
  };

  // - - - - - - - - - -

  /**
   * Handles verification or creation of attributeDefaults for shared installers
   */
  const processSharedInstallerAttributeDefaults = () => {
    candidatesList.forEach((candidate) => {
      if (candidate.sharedInstallerFlag) {
        // check if attributeDefaults exist for this shared installer
        const sharedInstallerDefaults = attributesList.find(
          (attribute) =>
            attribute.companyCode === companyCode &&
            attribute._kf_payrollNo ===
              dataFormatting_payrollFields(candidate.payrollNo) &&
            attribute.date === "" &&
            attribute.location === locationFilter &&
            attribute.availability !== null &&
            attribute.availability?.length !== 0
        );

        // if not found, create them
        if (
          !sharedInstallerDefaults &&
          candidate.cLocationCode !== locationFilter
        ) {
          const newDefaults = {
            companyCode: companyCode,
            location: locationFilter,
            _kf_payrollNo: dataFormatting_payrollFields(candidate.payrollNo),
            date: "",
            id: createUuid(),
            availability: [
              {
                Mon: "OFF",
              },
              {
                Tue: "OFF",
              },
              {
                Wed: "OFF",
              },
              {
                Thr: "OFF",
              },
              {
                Fri: "OFF",
              },
              {
                Sat: "OFF",
              },
              {
                Sun: "OFF",
              },
            ],
          };

          createNewAttribute(newDefaults);
          console.log(
            `ModelContext (${mongoDatabase}) processSharedInstallerAttributeDefaults - Created defaults for shared installer ${candidate.payrollNo}`
          );
        } else {
          console.log(
            `ModelContext (${mongoDatabase}) processSharedInstallerAttributeDefaults - Defaults already exist or not needed for shared installer ${candidate.payrollNo}`
          );
        }
      }
    });
  };

  // - - - - - - - - - -

  /**
   * Inserts a new document into the active queue.
   * @param {Object} newDocument - The new document to insert.
   */
  const insertActiveQueueDocument = (newDocument) => {
    const newProjectsList = [...projectsList];
    const stringId = newDocument._id.toString();
    newDocument.mongoId = stringId;
    newProjectsList.push(newDocument);

    setProjectsList(newProjectsList);
    updateFilteredProjectsList(newProjectsList, locationFilter);
    console.log(
      "ModelContext (" +
        mongoDatabase +
        ") insertActiveQueueDocument document added, newLength: ",
      newProjectsList.length,
      "; projectsList: ",
      projectsList
    );
  };

  // - - - - - - - - - -

  /**
   * Inserts a new attribute document.
   * @param {Object} newDocument - The new attribute document to insert.
   */
  const insertAttributeDocument = (newDocument) => {
    const newAttributesList = [...attributesList];
    newAttributesList.push(newDocument);

    setAttributesList(newAttributesList);
    console.log(
      "ModelContext (" +
        mongoDatabase +
        ") insertAttributeDocument document added, newLength: ",
      newAttributesList.length,
      "; newAttributesList: ",
      newAttributesList
    );
  };

  // - - - - - - - - - -

  /**
   * Replaces an existing document in the active queue.
   * @param {Object} newDocument - The new document to replace the existing one.
   */
  const replaceActiveQueueDocument = (newDocument) => {
    var newProjectsList = [];
    var whichIndex = -1;
    projectsList.forEach((originalDocument, index) => {
      if (originalDocument.invoiceNo === newDocument.invoiceNo) {
        newProjectsList.push(newDocument);
        whichIndex = index;
      } else {
        newProjectsList.push(originalDocument);
      }
    });

    if (whichIndex !== -1) {
      setProjectsList(newProjectsList);
      updateFilteredProjectsList(newProjectsList, locationFilter);
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") replaceActiveQueueDocument document replaced, invoiceNo: ",
        newDocument.invoiceNo,
        "; whichIndex: ",
        whichIndex
      );
    } else {
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") replaceActiveQueueDocument document NOT replaced, NO invoiceNo matched..."
      );
    }
  };

  // - - - - - - - - - -

  /**
   * Replaces an existing attribute document.
   * @param {Object} newDocument - The new attribute document to replace the existing one.
   */
  const replaceAttributeDocument = (newDocument) => {
    var newAttributesList = [];
    var whichIndex = -1;
    attributesList.forEach((originalDocument, index) => {
      if (originalDocument.id === newDocument.id) {
        newAttributesList.push(newDocument);
        whichIndex = index;
      } else {
        newAttributesList.push(originalDocument);
      }
    });

    if (whichIndex !== -1) {
      setAttributesList(newAttributesList);
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") replaceAttributeDocument document replaced, uuid: ",
        newDocument.id,
        "; whichIndex: ",
        whichIndex
      );
    } else {
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") replaceAttributeDocument document NOT replaced, NO uuid matched..."
      );
    }
  };

  // - - - - - - - - - -

  /**
   * Deletes a document from the active queue.
   * @param {Object} newDocument - The document to delete.
   */
  const deleteActiveQueueDocument = (newDocument) => {
    var newProjectsList = [];
    var whichIndex = -1;
    projectsList.forEach((originalDocument, index) => {
      if (originalDocument.id === newDocument.id) {
        // do not add to new list
        whichIndex = index;
      } else {
        newProjectsList.push(originalDocument);
      }
    });

    if (whichIndex !== -1) {
      setProjectsList(newProjectsList);
      updateFilteredProjectsList(newProjectsList, locationFilter);
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") deleteActiveQueueDocument document deleted, uuid: ",
        newDocument.id,
        "; whichIndex: ",
        whichIndex
      );
    } else {
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") deleteActiveQueueDocument document NOT deleted, NO uuid matched..."
      );
    }
  };

  // - - - - - - - - - -

  /**
   * Deletes an attribute document.
   * @param {Object} newDocument - The attribute document to delete.
   */
  const deleteAttributeDocument = (newDocument) => {
    var newAttributesList = [];
    var whichIndex = -1;
    attributesList.forEach((originalDocument, index) => {
      if (originalDocument.id === newDocument.id) {
        // do not add to new list
        whichIndex = index;
      } else {
        newAttributesList.push(originalDocument);
      }
    });

    if (whichIndex !== -1) {
      setAttributesList(newAttributesList);
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") deleteAttributeDocument document deleted, uuid: ",
        newDocument.id,
        "; whichIndex: ",
        whichIndex
      );
    } else {
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") deleteAttributeDocument document NOT deleted, NO uuid matched..."
      );
    }
  };

  // - - - - - - - - - -

  /**
   * Processes change events from the database.
   * @param {Array} changes - The array of change events.
   * @returns {Array} - The processed change events.
   */
  const processChangeEvents = (changes) => {
    // console.log("ModelContext (" + mongoDatabase + ") processChangeEvents length: ", changes.length);
    const result = changes.map((event) => {
      const whichCollection = event.ns.coll;
      const operationType = event.operationType;
      const theDocument = event.fullDocument;
      const affectedId = theDocument._id.toString();
      var changedDocumentArray = [];

      //
      // ActiveQueue Collection
      if (whichCollection === "ActiveQueue") {
        const invoiceNo = theDocument.invoiceNo;
        const location = theDocument.location;

        if (location === locationFilter && operationType === "insert") {
          // insert
          insertActiveQueueDocument(theDocument);
          console.log(
            "INSERT changedDocument _id: ",
            affectedId,
            "; location: ",
            location,
            "; invoiceNo: ",
            invoiceNo
          );
          //
        } else if (location === locationFilter && operationType === "replace") {
          // replace
          // pre-check to see if invoiceNo can find a projectsList match with original invoiceNo
          changedDocumentArray = projectsList.filter(
            (document) => document.invoiceNo === invoiceNo
          );

          if (changedDocumentArray.length > 0) {
            replaceActiveQueueDocument(theDocument);
            console.log(
              "REPLACE changedDocument invoiceNo match found(",
              changedDocumentArray.length,
              "), changedDocumentArray: ",
              changedDocumentArray,
              "), newDocument: ",
              theDocument
            );
          } else {
            console.log(
              "skipping REPLACE, NO changedDocument invoiceNo found in projectsList, _id: ",
              affectedId,
              "; location: ",
              location,
              "; invoiceNo: ",
              invoiceNo
            );
          }
          //
        } else if (location === locationFilter && operationType === "update") {
          // update
          // pre-check to see if invoiceNo can find a projectsList match with original invoiceNo
          changedDocumentArray = projectsList.filter(
            (document) => document.invoiceNo === invoiceNo
          );

          if (changedDocumentArray.length > 0) {
            replaceActiveQueueDocument(theDocument);
            console.log(
              "UPDATE changedDocument invoiceNo match found(",
              changedDocumentArray.length,
              "), changedDocumentArray: ",
              changedDocumentArray,
              "), newDocument: ",
              theDocument
            );
          } else {
            console.log(
              "skipping UPDATE, NO changedDocument invoiceNo found in projectsList, _id: ",
              affectedId,
              "; location: ",
              location,
              "; invoiceNo: ",
              invoiceNo
            );
          }
          //
        } else if (location === locationFilter && operationType === "delete") {
          // delete
          // pre-check to see if invoiceNo can find a projectsList match with original invoiceNo
          changedDocumentArray = projectsList.filter(
            (document) => document.invoiceNo === invoiceNo
          );

          if (changedDocumentArray.length > 0) {
            deleteActiveQueueDocument(theDocument);
            console.log(
              "DELETE changedDocument invoiceNo match found(",
              changedDocumentArray.length,
              "), changedDocumentArray: ",
              changedDocumentArray,
              "), newDocument: ",
              theDocument
            );
          } else {
            console.log(
              "skipping DELETE, NO changedDocument invoiceNo found in projectsList, _id: ",
              affectedId,
              "; operationType: ",
              operationType,
              "; locationFilter: ",
              locationFilter,
              "; location: ",
              location,
              "; invoiceNo: ",
              invoiceNo
            );
          }
          //
        } else {
          // unknown type or wrong location
          console.log(
            "skipping event, wrong location and/or operation, _id: ",
            affectedId,
            "; locationFilter: ",
            locationFilter,
            "; location: ",
            location,
            "; operationType: ",
            operationType
          );
        }

        //
        // Attributes Collection
      } else if (whichCollection === "Attributes") {
        const location = theDocument.location;
        const uuid = theDocument.id;

        if (location === locationFilter && operationType === "insert") {
          // insert
          // pre-check to see if uuid can find a attributesList match with original uuid
          const changedDocumentArray = attributesList.filter(
            (document) => document.id === uuid
          );

          if (changedDocumentArray.length === 0) {
            // insert
            insertAttributeDocument(theDocument);
            console.log(
              "INSERT changedDocument _id: ",
              affectedId,
              "; location: ",
              location
            );
          } else {
            console.log(
              "skipping INSERT, document uuid found in attributesList so DON'T duplicate it, _id: ",
              affectedId,
              "; location: ",
              location,
              "; uuid: ",
              uuid
            );
          }
          //
        } else if (location === locationFilter && operationType === "update") {
          // update
          // pre-check to see if uuid can find a attributesList match with original uuid
          const changedDocumentArray = attributesList.filter(
            (document) => document.id === uuid
          );

          if (changedDocumentArray.length > 0) {
            replaceAttributeDocument(theDocument);
            console.log(
              "UPDATE/REPLACE changedDocument uuid match found(",
              changedDocumentArray.length,
              "), changedDocumentArray: ",
              changedDocumentArray,
              "), newDocument: ",
              theDocument
            );
          } else {
            console.log(
              "skipping UPDATE/REPLACE, NO changedDocument uuid found in attributesList, _id: ",
              affectedId,
              "; location: ",
              location,
              "; uuid: ",
              uuid
            );
          }
          //
        } else if (location === locationFilter && operationType === "delete") {
          // delete
          // pre-check to see if uuid can find a attributesList match with original uuid
          const changedDocumentArray = attributesList.filter(
            (document) => document.id === uuid
          );

          if (changedDocumentArray.length > 0) {
            deleteAttributeDocument(theDocument);
            console.log(
              "DELETE document uuid match found(",
              changedDocumentArray.length,
              "), changedDocumentArray: ",
              changedDocumentArray,
              "), newDocument: ",
              theDocument
            );
          } else {
            console.log(
              "skipping DELETE, NO document uuid found in attributesList, _id: ",
              affectedId,
              "; operationType: ",
              operationType,
              "; locationFilter: ",
              locationFilter,
              "; location: ",
              location,
              "; uuid: ",
              uuid
            );
          }
          //
        } else {
          console.log(
            "skipping event, wrong location and/or operation, _id: ",
            affectedId,
            "; locationFilter: ",
            locationFilter,
            "; location: ",
            location,
            "; operationType: ",
            operationType
          );
        }

        //
        // unknown Collection
      } else {
        console.log(
          "ModelContext (" +
            mongoDatabase +
            ") processChangeEvents INVALID collection: ",
          whichCollection,
          "; operationType: ",
          operationType,
          "; theDocument: ",
          theDocument
        );
      }

      return true;
    });
  };

  // - - - - - - - - - -

  /**
   * Sets up user settings based on the user document.
   */
  const setupUserSettings = () => {
    // darkMode
    const userUImode =
      userDocument.defaultUImode !== ""
        ? userDocument.defaultUImode
        : defaultUImode;

    // jobTitleConfig
    const userJobTitleConfig =
      userDocument.jobTitleConfig !== ""
        ? userDocument.jobTitleConfig
        : defaultJobTitleConfig;

    // companyCode (KI or SCG)
    const userCompanyCode =
      userDocument.defaultCompany !== ""
        ? userDocument.defaultCompany
        : defaultCompanyCode;

    // location (CHS, RVS, BHM, etc)
    const userLocation =
      userDocument.defaultLocation !== ""
        ? userDocument.defaultLocation
        : defaultLocationFilter;

    // startDate (week beginning with)
    const userStartDate =
      userDocument.defaultDate !== ""
        ? userDocument.defaultDate
        : defaultStartDate;

    const userTab =
      userDocument.defaultTab !== "" ? userDocument.defaultTab : defaultTab;

    // filters (classification, surface, crewType, etc)
    const userFilters =
      userDocument.defaultFilters !== ""
        ? userDocument.defaultFilters
        : defaultFilters;

    // screenAccess (classification, surface, crewType, etc)
    const userScreenAccess =
      userDocument.screenAccessList !== ""
        ? userDocument.screenAccessList
        : defaultScreenAccessList;

    // lock/unlock button access
    const userRoleList = userDocument.roleList;
    var userLockAccess_unassigned = false;
    var userLockAccess_assigned = false;

    // InstallMgrs
    if (userRoleList.find((obj) => obj === "im_all")) {
      userLockAccess_unassigned = true;
      userLockAccess_assigned = true;

      // Coordinators
    } else if (userRoleList.find((obj) => obj === "coord_all")) {
      userLockAccess_unassigned = true;
      userLockAccess_assigned = false;
    }

    console.log(
      "ModelContext (" + mongoDatabase + ") setupUserSettings, userUImode: ",
      userUImode,
      ", setCompanyCode: ",
      userCompanyCode,
      ", setLocationFilter: ",
      userLocation,
      ", userStartDate: ",
      userStartDate,
      ", userTab: ",
      userTab,
      ", userLockAccess_unassigned: ",
      userLockAccess_unassigned,
      ", userLockAccess_assigned: ",
      userLockAccess_assigned,
      ", userScreenAccess: ",
      userScreenAccess
    );
    setDarkMode(userUImode === "dark" ? true : false);
    setJobTitleConfig(userJobTitleConfig);
    setLocationFilter(userLocation);
    setCompanyCode(userCompanyCode);
    setMondayThisWeek(userStartDate);
    setWhichList(userTab);
    setScreenAccess(userScreenAccess);
    setLockAccess_unassigned(userLockAccess_unassigned);
    setLockAccess_assigned(userLockAccess_assigned);
    setCurrentFilters(userFilters);
  };

  // - - - - - - - - - -

  /**
   * Sets up a change stream for the active queue.
   * @param {string} companyCode - The company code.
   * @param {string} locationFilter - The location filter.
   */
  const setupActiveQueueChangeStream = async (companyCode, locationFilter) => {
    // currently creates a new stream every time the locationFilter changes; only an app/browser reload wipes them out
    // currently receives the entire document; later probably need to "filter" which fields are received, to match original projectsList query
    // originally tried matching REPLACE events with mongoId, but field is spelled differently between companies and a chance that that field won't be populated at event time
    //
    //
    if (mongoApp.currentUser != null) {
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") setupActiveQueueChangeStream initiated..."
      );
      const mongo = mongoApp.currentUser.mongoClient(MONGODB_DATASOURCE);
      const collectionActiveQueue = mongo
        .db(mongoDatabase)
        .collection(MONGODB_COLLECTION_ACTIVEQUEUE);
      // https://stackoverflow.com/questions/76316442/change-stream-not-triggering-with-specific-pipeline-in-mongodb
      // https://www.mongodb.com/docs/realm/web/mongodb/#watch-for-changes-in-a-collection-with-a-filter
      const pipelineActiveQueue = {
        filter: {
          "fullDocument.companyCode": companyCode,
          "fullDocument.location": locationFilter,
        },
      };
      // https://www.mongodb.com/docs/manual/changeStreams/
      // Lookup Full Document for Update Operations
      const changeStreamActiveQueue = collectionActiveQueue.watch(
        pipelineActiveQueue,
        {
          fullDocument: "updateLookup",
        }
      );
      // NOTE: this try/catch is async, once initiated it can't see later changes to State. "break;" will kill it but no way to dynamically trigger
      try {
        for await (const change of changeStreamActiveQueue) {
          const { ns } = change;
          console.log(
            `ModelContext (${mongoDatabase}) setupActiveQueueChangeStream new ${JSON.stringify(
              ns
            )} event: `,
            change
          );
          // add to list of events
          setEvents((events) => [...events, change]);
          // Get today's date and time
          var nowActiveQueue = new Date();
          // update UI with TS
          setLatestChangeTS(nowActiveQueue);
        }
      } catch (error) {
        console.error(error);
        setNetworkErrorFlag(true);
      }
    }
  };

  // - - - - - - - - - -

  /**
   * Sets up a change stream for attributes.
   * @param {string} companyCode - The company code.
   * @param {string} locationFilter - The location filter.
   */
  const setupAttributesChangeStream = async (companyCode, locationFilter) => {
    // currently creates a new stream every time the locationFilter changes; only an app/browser reload wipes them out
    // currently receives the entire document; later probably need to "filter" which fields are received, to match original projectsList query
    // originally tried matching REPLACE events with mongoId, but field is spelled differently between companies and a chance that that field won't be populated at event time
    //
    //
    if (mongoApp.currentUser != null) {
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") setupAttributesChangeStream initiated..."
      );
      const mongo = mongoApp.currentUser.mongoClient(MONGODB_DATASOURCE);
      const collectionAttributes = mongo
        .db(mongoDatabase)
        .collection(MONGODB_COLLECTION_ATTRIBUTES);
      // https://stackoverflow.com/questions/76316442/change-stream-not-triggering-with-specific-pipeline-in-mongodb
      // https://www.mongodb.com/docs/realm/web/mongodb/#watch-for-changes-in-a-collection-with-a-filter
      const pipelineAttributes = {
        filter: {
          "fullDocument.companyCode": companyCode,
          "fullDocument.location": locationFilter,
        },
      };
      // https://www.mongodb.com/docs/manual/changeStreams/
      // Lookup Full Document for Update Operations
      const changeStreamAttributes = collectionAttributes.watch(
        pipelineAttributes,
        {
          fullDocument: "updateLookup",
        }
      );
      // NOTE: this try/catch is async, once initiated it can't see later changes to State. "break;" will kill it but no way to dynamically trigger
      try {
        for await (const change of changeStreamAttributes) {
          const { ns } = change;
          console.log(
            `ModelContext (${mongoDatabase}) setupAttributesChangeStream new ${JSON.stringify(
              ns
            )} event: `,
            change
          );
          // add to list of events
          setEvents((events) => [...events, change]);
          // Get today's date and time
          var nowAttributes = new Date();
          // update UI with TS
          setLatestChangeTS(nowAttributes);
        }
      } catch (error) {
        console.error(error);
        setNetworkErrorFlag(true);
      }
    }
  };

  // - - - - - - - - - -

  /**
   * Updates attribute IDs with newly created MongoDB IDs.
   */
  const updateAttributeIds = () => {
    //update local Lists with the newly-created MongoDB _ids
    var workingAttributeList = [...attributesList];
    // create working list
    const workingArray = [...newAttributeList];
    console.log(
      "ModelContext (" + mongoDatabase + ") updateAttributeIds, count: ",
      workingArray.length
    );

    // loop thru each
    workingArray.forEach((newAttribute) => {
      // find master list index of the affected object
      var originalIndex = workingAttributeList.findIndex(
        (obj) => obj.id === newAttribute.originalId
      );
      if (originalIndex !== -1) {
        // update local value
        workingAttributeList[originalIndex]._id = ObjectId(newAttribute.mongoId)
          ? ObjectId(newAttribute.mongoId)
          : "";
        // set local list (which in turn will update the scheduleArray)
        setAttributesList(workingAttributeList);
      }
    });

    // clear newAttributeList
    setNewAttributeList([]);
  };

  // - - - - - - - - - -

  const updateUnassignedAttributeList = (date, value) => {};

  // - - - - - - - - - -

  /**
   * Updates the attribute list for a specified date and reference.
   * @param {string} date - The date to update.
   * @param {string} reference - The reference (payroll number).
   * @param {string} type - The type of update (e.g., "Lock").
   * @param {string} value - The value to update.
   */
  const updateAttributeList = (date, reference, type, value) => {
    // reference is payrollNo with dataFormatting_payrollFields() applied
    var currentValueArray = [];
    // filter for matching date & installer
    // 2025-02-11 - added filter for availability length to prevent defaults from being included
    currentValueArray = attributesList.filter((document) => {
      const docDate = new Date(document.date).toLocaleDateString("en-us");
      const whichRow = reference === "Unassig" ? "" : reference;
      const availabilityLength = document.availability
        ? document.availability?.length
        : 0;
      return (
        document._kf_payrollNo === whichRow &&
        docDate === date &&
        availabilityLength === 0
      );
    });
    // }
    console.log(
      "ModelContext (" +
        mongoDatabase +
        ") updateAttributeList, currentValueArray: ",
      currentValueArray
    );

    var newAttributesList = [];
    var currentAttribute = {};
    var newJobEncoded = "";

    switch (type) {
      case "Close":
        // handled by IF statement in calling function
        // so we shouldn't have any occurances
        break;

      case "Available":
        newJobEncoded = "";
        break;

      case "OFF":
        newJobEncoded = "OFF";
        break;

      case "BUFFER":
        newJobEncoded = "BUFFER";
        break;

      case "Custom":
        newJobEncoded = "🔥" + value + "🔥";
        break;

      case "Lock":
        // handled below (need previous value)
        break;

      default:
        console.log(
          "ModelContext (" +
            mongoDatabase +
            ") updateAttributeList, switch(type) has branched to default due to UNHANDLED value: ",
          type
        );
        break;
    }

    if (currentValueArray.length > 0) {
      // if found it should have only one match
      currentAttribute = currentValueArray[0];
      // get original values
      const currentMongoId = currentAttribute._id?.toString();
      // set new values
      currentAttribute.locked =
        type === "Lock" && !currentAttribute.locked ? true : false;
      currentAttribute.encodedSmallTitle = newJobEncoded;
      currentAttribute.encodedLargeTitle = newJobEncoded;
      currentAttribute.availability = [];

      var originalIndex = 0;
      // find attribute (index) in current list
      originalIndex = attributesList.findIndex((document) => {
        const docDate = new Date(document.date).toLocaleDateString("en-us");
        const whichRow = reference === "Unassig" ? "" : reference;
        const availabilityLength = document.availability
          ? document.availability?.length
          : 0;
        return (
          document._kf_payrollNo === whichRow &&
          docDate === date &&
          availabilityLength === 0
        );
      });
      // }

      if (type === "Available") {
        // remove attribute from local list
        newAttributesList = [...attributesList];
        newAttributesList.splice(originalIndex, 1);
        // update Mongo by removing pertinent data, since changeStream DELETE notifications are not working
        // this was broken until I realized this field needed to be added to $SET in setAttributeById
        // NOTE: this will cause a buildup over time of orphaned Attribute Documents, since further editing will not find a payrollNo match, so a new entry will occur
        currentAttribute._kf_payrollNo = "";
        setAttributeById(currentMongoId, currentAttribute);
        // no longer deleting, since deletion tends to happen before updating
        // remove from Mongo
        // deleteAttributeDocumentById(currentMongoId);
        //
      } else {
        // create new list by pushing originals & new replacement attribute in correct index spot
        newAttributesList = [];
        attributesList.forEach((originalDocument, index) => {
          newAttributesList.push(
            index === originalIndex ? currentAttribute : originalDocument
          );
        });
        console.log(
          "ModelContext (" +
            mongoDatabase +
            ") updateAttributeList, attributeList replaced at index: ",
          originalIndex,
          ", currentAttribute: ",
          currentAttribute
        );
        // add to Mongo
        setAttributeById(currentMongoId, currentAttribute);
      }

      // update local attributeList
      setAttributesList(newAttributesList);
      //
    } else {
      // if NOT found, create one
      if (type !== "Available") {
        // "Available" would create a blank ("") line...
        currentAttribute = {};
        currentAttribute.companyCode = companyCode;
        currentAttribute.date = date;
        // reference is payrollNo with dataFormatting_payrollFields() applied
        currentAttribute._kf_payrollNo =
          reference === "Unassig" || type === "Expedite" || type === "Distant"
            ? ""
            : reference;
        currentAttribute.location = locationFilter;
        currentAttribute.id = createUuid();
        currentAttribute.encodedSmallTitle = newJobEncoded;
        currentAttribute.encodedLargeTitle = newJobEncoded;

        // NOTE: during attribute rewrite I determined to remove old v2 attributes
        currentAttribute.distant = false;
        currentAttribute.expedite = false;
        currentAttribute.locked = type === "Lock" ? true : false;
        currentAttribute.invoiceNo = "";
        currentAttribute.availability = [];
        // currentAttribute.crewNo = "";
        // currentAttribute.multi = false;
        // currentAttribute.portal = false;
        // currentAttribute.rush = false;
        // currentAttribute.buffer = newBuffer;

        // newAttributesList = [...attributesList, currentAttribute];
        console.log(
          "ModelContext (" +
            mongoDatabase +
            ") updateAttributeList, attribute added, currentAttribute: ",
          currentAttribute
        );

        // setAttributesList(newAttributesList);
        createNewAttribute(currentAttribute);
      }
    }
  };

  // - - - - - - - - - -

  /**
   * Updates the filtered candidates list.
   * @param {Array} unfilteredCandidatesList - The unfiltered candidates list.
   */
  const updateFilteredCandidatesList = (unfilteredCandidatesList) => {
    var newFilteredList = unfilteredCandidatesList;
    var previousList = newFilteredList;

    // create filtered list using crew_detailer
    if (!currentFilters.crewFilters[FILTERS_CREW_DETAILER]) {
      newFilteredList = previousList.filter(
        (document) => document.RecordType !== "Detailer"
      );
      previousList = newFilteredList;
    }

    // create filtered list using crew_installer
    if (!currentFilters.crewFilters[FILTERS_CREW_INSTALLER]) {
      newFilteredList = previousList.filter(
        (document) => document.RecordType !== "Installer"
      );
      previousList = newFilteredList;
    }

    // create filtered list using crew_helper
    if (!currentFilters.crewFilters[FILTERS_CREW_HELPER]) {
      newFilteredList = previousList.filter(
        (document) => document.RecordType !== "Helper"
      );
      previousList = newFilteredList;
    }

    // create filtered list using crew_employee
    if (!currentFilters.crewFilters[FILTERS_CREW_EMPLOYEE]) {
      newFilteredList = previousList.filter(
        (document) => document.RecordType !== "Employee"
      );
      previousList = newFilteredList;
    }

    // create filtered list using crew_soft_surface
    if (!currentFilters.crewSurfaceFilters[FILTERS_CREW_SURFACE_SOFT]) {
      newFilteredList = previousList.filter(
        (document) => document.Scheduling_Surface !== "Soft Surface"
      );
      previousList = newFilteredList;
    }

    // create filtered list using crew_hard_surface
    if (!currentFilters.crewSurfaceFilters[FILTERS_CREW_SURFACE_HARD]) {
      newFilteredList = previousList.filter(
        (document) => document.Scheduling_Surface !== "Hard Surface"
      );
      previousList = newFilteredList;
    }

    // sort the resulting list
    newFilteredList = myJsonArraySort(
      newFilteredList,
      "payrollNo",
      "Number",
      "ascending"
    );
    newFilteredList = myJsonArraySort(
      newFilteredList,
      "Scheduling_Surface",
      "String/Text",
      "descending"
    );
    setFilteredCandidatesList(newFilteredList);
  };

  // - - - - - - - - - -

  /**
   * Updates the filtered projects list.
   * @param {Array} unfilteredProjectsList - The unfiltered projects list.
   * @param {string} location - The location filter.
   */
  const updateFilteredProjectsList = (unfilteredProjectsList, location) => {
    // create filtered list using statusCode (remove cancellations)
    var newFilteredList = unfilteredProjectsList.filter(
      (document) => document.statusCode !== 20
    );
    var previousList = newFilteredList;

    // create filtered list using statusCode (remove created)
    //    08-08-2024: Scrum discussion: remove this filtering to prevent double booking
    //    08-12-2024: Scrum discussion; this change is only applicable to "prescheduled", otherwise show RTS
    newFilteredList = previousList.filter(
      (document) =>
        (document.statusCode >= 3 && document.date_scheduled === "") ||
        document.date_scheduled !== ""
    );
    previousList = newFilteredList;

    // create filtered list using location
    // 2025-02-14 - removed this filter so that shared installer jobs would not be filtered out

    // newFilteredList = previousList.filter(
    //   (document) => document.location === location
    // );
    // previousList = newFilteredList;

    // create filtered list using surface_detail
    if (!currentFilters.surfaceFilters[FILTERS_SURFACE_DETAIL]) {
      newFilteredList = previousList.filter(
        (document) => document.surface !== "Detail"
      );
      previousList = newFilteredList;
    }

    // create filtered list using surface_carpet
    if (!currentFilters.surfaceFilters[FILTERS_SURFACE_CARPET]) {
      newFilteredList = previousList.filter(
        (document) => document.surface !== "Carpet"
      );
      previousList = newFilteredList;
    }

    // create filtered list using surface_vinyl
    if (!currentFilters.surfaceFilters[FILTERS_SURFACE_VINYL]) {
      newFilteredList = previousList.filter(
        (document) => document.surface !== "Vinyl"
      );
      previousList = newFilteredList;
    }

    // create filtered list using surface_plank
    if (!currentFilters.surfaceFilters[FILTERS_SURFACE_PLANK]) {
      newFilteredList = previousList.filter(
        (document) => document.surface !== "Plank"
      );
      previousList = newFilteredList;
    }

    // create filtered list using surface_tile
    if (!currentFilters.surfaceFilters[FILTERS_SURFACE_TILE]) {
      newFilteredList = previousList.filter(
        (document) => document.surface !== "Tile"
      );
      previousList = newFilteredList;
    }

    // create filtered list using surface_wood
    if (!currentFilters.surfaceFilters[FILTERS_SURFACE_WOOD]) {
      newFilteredList = previousList.filter(
        (document) => document.surface !== "Wood"
      );
      previousList = newFilteredList;
    }

    // create filtered list using surface_backsplash
    if (!currentFilters.surfaceFilters[FILTERS_SURFACE_BACKSPLASH]) {
      newFilteredList = previousList.filter(
        (document) => document.surface !== "Backsplash"
      );
      previousList = newFilteredList;
    }

    // create filtered list using surface_unknown
    if (!currentFilters.surfaceFilters[FILTERS_SURFACE_UNKNOWN]) {
      newFilteredList = previousList.filter(
        (document) => document.surface !== "UNK"
      );
      previousList = newFilteredList;
    }

    // create filtered list using classification_detail
    if (!currentFilters.classificationFilters[FILTERS_CLASSIFICATION_DETAIL]) {
      newFilteredList = previousList.filter(
        (document) => document.classification !== "Detail"
      );
      previousList = newFilteredList;
    }

    // create filtered list using classification_job
    if (!currentFilters.classificationFilters[FILTERS_CLASSIFICATION_JOB]) {
      newFilteredList = previousList.filter(
        (document) => document.classification !== "Job"
      );
      previousList = newFilteredList;
    }

    // create filtered list using classification_related_po
    if (
      !currentFilters.classificationFilters[FILTERS_CLASSIFICATION_RELATED_PO]
    ) {
      newFilteredList = previousList.filter(
        (document) => document.classification !== "Related PO"
      );
      previousList = newFilteredList;
    }

    // create filtered list using classification_workorder_expense
    if (
      !currentFilters.classificationFilters[
        FILTERS_CLASSIFICATION_WORKORDER_EXPENSE
      ]
    ) {
      newFilteredList = previousList.filter(
        (document) => document.classification !== "Workorder/Expense"
      );
      previousList = newFilteredList;
    }

    // create filtered list using classification_repair_order
    if (
      !currentFilters.classificationFilters[FILTERS_CLASSIFICATION_REPAIR_ORDER]
    ) {
      newFilteredList = previousList.filter(
        (document) => document.classification !== "Repair Order"
      );
      previousList = newFilteredList;
    }

    // create filtered list using classification_paid_in_error
    if (
      !currentFilters.classificationFilters[
        FILTERS_CLASSIFICATION_PAID_IN_ERROR
      ]
    ) {
      newFilteredList = previousList.filter(
        (document) => document.classification !== "Paid In Error"
      );
      previousList = newFilteredList;
    }

    // create filtered list using classification_other
    if (!currentFilters.classificationFilters[FILTERS_CLASSIFICATION_OTHER]) {
      newFilteredList = previousList.filter(
        (document) => document.classification !== "Other"
      );
      previousList = newFilteredList;
    }

    setFilteredProjectsList(newFilteredList);
  };

  // - - - - - - - - - -

  /**
   * Updates both filtered candidates and projects lists.
   * @param {Array} candidatesList - The candidates list.
   * @param {Array} projectsList - The projects list.
   * @param {string} locationFilter - The location filter.
   */
  const updateFilteredLists = (
    candidatesList,
    projectsList,
    locationFilter
  ) => {
    updateFilteredCandidatesList(candidatesList);
    updateFilteredProjectsList(projectsList, locationFilter);
  };

  // - - - - - - - - - -

  /**
   * Creates a new job attribute.
   * @param {Object} theJob - The job object.
   * @returns {Object} - The new attribute.
   */
  const createJobAttribute = (theJob) => {
    var newAttribute = { ...DOCUMENT_ATTRIBUTES_TEMPLATE };
    newAttribute.companyCode = theJob.companyCode;
    newAttribute.location = theJob.location;
    newAttribute.date = theJob.start;
    newAttribute.id = createUuid();
    newAttribute.invoiceNo = theJob.invoiceNo;

    const newAttributesList = [...attributesList, newAttribute];
    console.log(
      "ModelContext (" +
        mongoDatabase +
        ") createJobAttribute, attribute added, newAttribute: ",
      newAttribute
    );

    setAttributesList(newAttributesList);
    createNewAttribute(newAttribute);

    return newAttribute;
  };

  // - - - - - - - - - -

  /**
   * Creates a new extra cell attribute.
   * @param {string} date - The date for the attribute.
   * @param {string} surface - The surface type.
   * @param {string} customText - The custom text.
   * @returns {Object} - The new attribute.
   */
  const createExtraCellAttribute = (date, surface, customText) => {
    console.log(
      `ModelContext (${mongoDatabase}) createExtraCellAttribute date: `,
      date,
      ", surface: ",
      surface,
      ", customText: ",
      customText
    );
    var newAttribute = { ...DOCUMENT_ATTRIBUTES_TEMPLATE };
    newAttribute.companyCode = companyCode;
    newAttribute.location = locationFilter;
    newAttribute.id = createUuid();
    newAttribute.date = date;
    newAttribute.extra = { surface: surface, customText: customText };

    // update local list
    const newAttributesList = [...attributesList, newAttribute];
    setAttributesList(newAttributesList);
    // add to MongoDB
    createNewAttribute(newAttribute);

    return newAttribute;
  };

  // - - - - - - - - - -

  /**
   * Creates a new crew defaults attribute.
   * @param {string} payrollNo - The payroll number.
   * @param {Array} dayAttributesDefault - The default day attributes.
   * @param {Array} customValuesDefault - The default custom values.
   * @returns {Object} - The new attribute.
   */
  const createCrewDefaultsAttribute = (
    payrollNo,
    dayAttributesDefault,
    customValuesDefault
  ) => {
    // convert CrewDetailModal state values (devcom created) to Attribute Document values
    var newArray = [];
    const days = ["Mon", "Tue", "Wed", "Thr", "Fri", "Sat", "Sun"];
    // replaced Switch(index) & newArray.push({ Mon: element }) with ES6 Dynamic key
    //    see:  https://stackoverflow.com/questions/2462800/how-do-i-create-a-dynamic-key-to-be-added-to-a-javascript-object-variable
    customValuesDefault.forEach((element, index) => {
      // customValuesDefault already has exactly 7 elements
      if (dayAttributesDefault[index]) {
        // if a default exists parse it's values & add to result
        if (dayAttributesDefault[index].status === "Custom") {
          newArray.push({ [days[index]]: element });
        } else {
          newArray.push({
            [days[index]]: dayAttributesDefault[index].status,
          });
        }
      }
    });

    // look for existing defaultAttribute
    const existingDefaultAttribute = attributesList.find(
      (document) =>
        document.companyCode === companyCode &&
        document.location === locationFilter &&
        document.date === "" &&
        document._kf_payrollNo === dataFormatting_payrollFields(payrollNo) &&
        document.availability?.length > 0
    );
    console.log(
      `ModelContext (${mongoDatabase}) createCrewDefaultsAttribute, existingDefaultAttribute: `,
      existingDefaultAttribute
    );
    var newAttributesList = [];
    if (existingDefaultAttribute?.availability) {
      // found existing Attribute Document
      const currentMongoId = existingDefaultAttribute._id?.toString();
      existingDefaultAttribute.availability = newArray;

      var originalIndex = 0;
      // find attribute (index) in current list
      originalIndex = attributesList.findIndex((document) => {
        return (
          document.companyCode === companyCode &&
          document.location === locationFilter &&
          document._kf_payrollNo === dataFormatting_payrollFields(payrollNo) &&
          document.availability
        );
      });

      // create new list by pushing originals & new replacement attribute in correct index spot
      newAttributesList = [];
      attributesList.forEach((originalDocument, index) => {
        newAttributesList.push(
          index === originalIndex ? existingDefaultAttribute : originalDocument
        );
      });

      // push changes to Mongo
      setAttributeById(currentMongoId, existingDefaultAttribute);
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") createCrewDefaultsAttribute, attribute updated, _id: ",
        currentMongoId,
        "; existingDefaultAttribute: ",
        existingDefaultAttribute
      );
      //
    } else {
      // not found - create a new one
      var newAttribute = { ...DOCUMENT_ATTRIBUTES_TEMPLATE };
      newAttribute.companyCode = companyCode;
      newAttribute.location = locationFilter;
      newAttribute.id = createUuid();
      newAttribute._kf_payrollNo = dataFormatting_payrollFields(payrollNo);
      newAttribute.availability = newArray;

      newAttributesList = [...attributesList, newAttribute];
      createNewAttribute(newAttribute);
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") createCrewDefaultsAttribute, attribute added, newAttribute: ",
        newAttribute
      );
    }
    setAttributesList(newAttributesList);

    return newAttribute;
  };

  // - - - - - - - - - -

  /**
   * Creates a list of payrollNos that need to be queried & added to candidateList.
   */
  const createCrewQueryList = () => {
    // find/handle "invisible" jobs
    //      i.e. scheduled, assigned job(s) for installers not in this location
    //
    // EXPLANATION OF CREWQUERYLIST:
    //    candidates are queried by location & additionalLocations fields
    //    jobs are queried by location field
    //    when a job is in the foundSet and assigned to an installer NOT included in the candidate foundSet,
    //       then it was agreed that another query would be performed to add that installer to the
    //       candidate foundSet so that the assigned, scheduled job(s) would be displayed correctly
    //

    var queryPayrollList = new Set();

    // loop thru unfiltered project documents
    projectsList.forEach((document, projectIndex) => {
      const start =
        document.date_scheduled !== ""
          ? new Date(document.date_scheduled).toLocaleDateString("en-us")
          : "";
      var end =
        document.date_estimatedCompletion !== ""
          ? new Date(document.date_estimatedCompletion).toLocaleDateString(
              "en-us"
            )
          : "";
      if (end === "") {
        end = start;
      }
      const installer =
        document.installer !== ""
          ? dataFormatting_payrollFields(document.installer)
          : "";

      if (start !== "") {
        // loop thru columns to determine which is applicable
        weekDatesArray.forEach((date, dateIndex) => {
          if (
            (new Date(start).getTime() >= new Date(date).getTime() &&
              new Date(end).getTime() <= new Date(date).getTime()) ||
            (new Date(date).getTime() >= new Date(start).getTime() &&
              new Date(date).getTime() <= new Date(end).getTime())
          ) {
            if (installer !== "") {
              // find applicable installer document
              const matchedCandidate = candidatesList.filter((candidate) => {
                const formattedPayrollNo = dataFormatting_payrollFields(
                  candidate.payrollNo
                );
                return formattedPayrollNo === installer;
              });
              if (matchedCandidate.length > 0) {
                // determine which surface type to increment applicable count
              } else {
                queryPayrollList.add(document.installer);
                const report =
                  "Inv#" +
                  document.invoiceNo +
                  ", Installer:" +
                  document.installer +
                  ", Start:" +
                  document.date_scheduled;
                console.log(
                  "ModelContext (" +
                    mongoDatabase +
                    ") createCrewQueryList ERROR - unable to match an assigned job to an installer in this list !!",
                  report
                );
              }
            }
          }
        });
      }
    });

    //update list so that queries can begin
    // NOTE:  help with new Set type:  https://dev.to/clairecodes/how-to-create-an-array-of-unique-values-in-javascript-using-sets-5dg6
    const queryPayrollListArray = Array.from(queryPayrollList); // OR const queryPayrollListArray = [...new Set(queryPayrollList)];
    setCrewQueryList(queryPayrollListArray);
    console.log(
      "ModelContext (" +
        mongoDatabase +
        ") createCrewQueryList crewQueryList: ",
      queryPayrollListArray
    );
  };

  // - - - - - - - - - -

  /**
   * Removes an extra cell attribute by ID.
   * @param {string} id - The ID of the attribute to remove.
   */
  const removeExtraCellAttribute = async (id) => {
    const currentExtraCellAttribute = attributesList.find(
      (document) => document.id === id
    );
    // update MongoDB with empty attribute values, essentually "deleting"
    const currentMongoId = currentExtraCellAttribute._id?.toString();
    currentExtraCellAttribute.date = "";
    currentExtraCellAttribute.deleted = true;
    currentExtraCellAttribute._kf_payrollNo = "";
    currentExtraCellAttribute.extra = false;
    currentExtraCellAttribute.invoiceNo = "";
    currentExtraCellAttribute.availability = {};
    console.log(
      "ExtraCellDetailModal onDelete currentExtraCellAttribute: ",
      currentExtraCellAttribute,
      ", id: ",
      currentMongoId
    );

    // send changes to MongoDB
    setAttributeById(currentMongoId, currentExtraCellAttribute);
  };

  // - - - - - - - - - -

  /**
   * Handles login errors.
   * @param {Error} err - The error object.
   */
  const handleLoginError = (err) => {
    console.log(
      "ModelContext (" +
        mongoDatabase +
        ") Login handleLoginError (alert to user 'Login failed'): ",
      err
    );
    alert("Login failed!");
  };

  // - - - - - - - - - -

  /**
   * Logs in a user using MSAL.
   * @param {string} jwt - The JWT token.
   */
  const loginMsal = async (jwt) => {
    setUserLoading(true);
    // console.log("ModelContext (" + mongoDatabase + ") loginMsal jwt: ", jwt);
    // Create a Custom JWT credential
    const credentials = Realm.Credentials.jwt(jwt);
    // Authenticate the user
    const mongoUser = await mongoApp.logIn(credentials);
    // `App.currentUser` updates to match the logged in user
    console.log(
      "ModelContext (" +
        mongoDatabase +
        ") loginMsal mongoApp.logIn -> mongoUser: ",
      mongoUser
    );
    console.log(
      "ModelContext (" + mongoDatabase + ") loginMsal name: ",
      mongoUser._profile.data.name,
      ", email: ",
      mongoUser._profile.data.email
    );
    // console.assert(mongoUser.id === mongoApp.currentUser.id);

    // LogRocket User Identity
    LogRocket.identify(mongoUser.id, {
      name: mongoUser._profile.data.name,
      email: mongoUser._profile.data.email,

      // Add your own custom user variables here, ie:
      subscriptionType: "customVarGoesHere",
    });

    setMongoUser(mongoUser);
    setUserLoading(false);
  };

  // // - - - - - - - - - -

  // const loginCoordChs = async () => {
  //   const mongoUser = await mongoApp
  //     .logIn(Realm.Credentials.apiKey(MONGODB_USER_APIKEY_COORD_CHS))
  //     .catch(handleLoginError);
  //   console.log("ModelContext (" + mongoDatabase + ") Login mongoUser: ", mongoUser);
  //   setMongoUser(mongoUser);
  //   // id: 65ba5d93ec8da338461cd665
  // };

  // // - - - - - - - - - -

  // const loginCoordAll = async () => {
  //   const mongoUser = await mongoApp
  //     .logIn(Realm.Credentials.apiKey(MONGODB_USER_APIKEY_COORD_ALL))
  //     .catch(handleLoginError);
  //   console.log("ModelContext (" + mongoDatabase + ") Login mongoUser: ", mongoUser);
  //   setMongoUser(mongoUser);
  //   // id: 65ba6f6c648cd71278412353
  // };

  // // - - - - - - - - - -

  // const loginImAtl = async () => {
  //   const mongoUser = await mongoApp
  //     .logIn(Realm.Credentials.apiKey(MONGODB_USER_APIKEY_IM_ATL))
  //     .catch(handleLoginError);
  //   console.log("ModelContext (" + mongoDatabase + ") Login mongoUser: ", mongoUser);
  //   setMongoUser(mongoUser);
  //   // id: 65ba6f9932bdc747b85e37dd
  // };

  // // - - - - - - - - - -

  // const loginDetailerBrownt = async () => {
  //   const mongoUser = await mongoApp
  //     .logIn(Realm.Credentials.apiKey(MONGODB_USER_APIKEY_DETAILER_BROWNT))
  //     .catch(handleLoginError);
  //   console.log("ModelContext (" + mongoDatabase + ") Login mongoUser: ", mongoUser);
  //   setMongoUser(mongoUser);
  //   // id: 65ba6f0bbc8876f03a089f15
  // };

  /**
   * Logs out the current user.
   */
  const logoutUser = async () => {
    console.log("ModelContext (" + mongoDatabase + ") Logout...", mongoUser);
    if (mongoUser) {
      const result = await mongoUser.logOut();
      setMongoUser(null);
      // clear existing data
      setUserDocument([]);
      setProjectsList([]);
      updateFilteredProjectsList([], locationFilter);
      updateFilteredCandidatesList([]);
      setCandidatesList([]);
      setLocationFilter("");
      setCompanyCode("");
    }
  };

  // - - - - - - - - - -

  /**
   * Gets unique locations for a company.
   * @param {string} companyCode - The company code.
   * @returns {Array} - The array of unique locations.
   */
  const getUniqueLocations = async (companyCode) => {
    if (mongoApp.currentUser != null) {
      var newArray = [];
      if (userDocument.locationList.length === 0) {
        // if User doc locationList is empty then provide fuul company list of locations via query
        const mongo = mongoApp.currentUser.mongoClient(MONGODB_DATASOURCE);
        const collection = mongo
          .db(mongoDatabase)
          .collection(MONGODB_COLLECTION_ACTIVEQUEUE);

        if (!locationsLoading) {
          setLocationsLoading(true);

          const returnedList = await collection.aggregate([
            {
              $match: {
                companyCode: { $eq: companyCode },
              },
            },
            {
              $group: {
                _id: null,
                location: { $addToSet: "$location" },
              },
            },
            {
              $unwind: "$location",
            },
            {
              $project: {
                _id: 0,
              },
            },
          ]);
          newArray = returnedList
            // transform array of objects into array of strings and then sort them ascending
            .map((value, index) => {
              return value.location;
            })
            .sort();
        }
      } else {
        newArray = userDocument.locationList;
      }

      console.log(
        "ModelContext (" + mongoDatabase + ") getUniqueLocations: ",
        newArray
      );
      setLocationArray(newArray);
      setLocationsLoading(false);
      return newArray;
    }
  };

  // - - - - - - - - - -

  /**
   * Gets a user by ID.
   * @param {string} userId - The user ID.
   * @returns {Object} - The user document.
   */
  const getUserById = async (userId) => {
    // NOTE: in "index.js", when <React.StrictMode> is on, useEffects are triggered
    //         twice, so this causes 2 async requests in this function
    //         which then triggers "createNewUser()" twice, which creates multiple
    //         new records in the Users table
    //
    // - - - - -

    const handleGetUserError = (err) => {
      setUserLoading(false);
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") getUserById handleLoginError (alert to user 'User findOne error!'): ",
        err
      );
      alert("User findOne error!");
    };

    // - - - - -
    //
    console.log(
      "ModelContext (" + mongoDatabase + ") getUserById, userId: ",
      userId
    );
    if (mongoApp.currentUser != null && !userLoading) {
      const mongo = mongoApp.currentUser.mongoClient(MONGODB_DATASOURCE);
      const collection = mongo
        .db(mongoDatabase)
        .collection(MONGODB_COLLECTION_USERS);

      const returnedRecord = await collection
        .findOne({
          id: userId,
        })
        .catch(handleGetUserError);
      if (returnedRecord) {
        console.log(
          "ModelContext (" + mongoDatabase + ") getUserById: ",
          returnedRecord ? returnedRecord : ""
        );
        setUserDocument(returnedRecord);
        setUserLoading(false);
      } else {
        // user record not found - create a new one
        console.log(
          "ModelContext (" + mongoDatabase + ") getUserById (",
          userId,
          ") returned null (likely 401 not found)"
        );
        createNewUser();
      }
      return returnedRecord;
    }
  };

  // - - - - - - - - - -

  /**
   * Gets a project by ID.
   * @param {string} id - The project ID.
   * @returns {Object} - The project document.
   */
  const getProjectById = async (id) => {
    if (mongoApp.currentUser != null) {
      const mongo = mongoApp.currentUser.mongoClient(MONGODB_DATASOURCE);
      const collection = mongo
        .db(mongoDatabase)
        .collection(MONGODB_COLLECTION_ACTIVEQUEUE);

      if (!projectsLoading) {
        setProjectsLoading(true);
        const returnedRecord = await collection.findOne({
          _id: ObjectId(id),
        });
        console.log(
          "ModelContext (" + mongoDatabase + ") getProjectById: ",
          returnedRecord
        );
        setProjectsLoading(false);
        return returnedRecord;
      }
    }
  };

  // - - - - - - - - - -

  /**
   * Gets projects by company and location.
   * @param {string} companyCode - The company code.
   * @param {string} locationFilter - The location filter.
   * @returns {Array} - The array of project documents.
   */
  const getProjectsByCompany = async (companyCode, locationFilter) => {
    //
    // NOTE: on 04/26-17/2024 it was discovered that MongoIDs were not being updated in FM AQ table
    //         since 03/17/2023, so batched data was created and pushed (50K processes!)
    //       it was then discovered that 2 things were hindering accurate data and they were repaired:
    //         1) Inserts were using layout "ActiveQueue [KI_ActiveQueue DATA]"
    //            Updates were using layout "ActiveQueue_MongoDB"
    //               this was causing LineItems & Materials to not be provided for Inserts
    //               to repair, both Insert & Update now use "ActiveQueue_MongoDB"
    //         2) neither layouts "ActiveQueue [KI_ActiveQueue DATA]" nor "ActiveQueue_MongoDB" had
    //               the expressShip field
    //       since some/all of these missing fields were there in the past it was presumed they got "lost"
    //          during some migration from dev->prod
    //       these fixes were made in both dev & prod
    //
    // - - - - - - - - - -

    const processDuplicateProjectDocuments = (incomingList) => {
      var duplicateIdList = new Set();
      incomingList.forEach((currentDocument) => {
        const currentId = currentDocument.id;
        const matchedDocuments = incomingList.filter(
          // create array of matching ids
          (document) => document.id === currentId
        );
        if (matchedDocuments.length > 1) {
          // duplicate ids found
          matchedDocuments.forEach((duplicatedDocument, index) => {
            if (index > 0) {
              // ignore first in list [0] - that's the first/original occurance; keep it, remove the others
              const duplicatedMongoId = duplicatedDocument._id.toString();
              duplicateIdList.add(duplicatedMongoId);
              const dupIndex = incomingList.findIndex(
                (i) => i._id.toString() === duplicatedMongoId
              );
              if (dupIndex > -1) {
                // only splice array when item is found
                incomingList.splice(dupIndex, 1);
              }
              deleteAQDocumentById(duplicatedMongoId);
            }
          });
        }
      });
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") processDuplicateProjectDocuments, duplicatedProjectIds: ",
        duplicateIdList
      );
      return incomingList;
    };

    // - - - - - - - - - -

    if (mongoApp.currentUser != null) {
      const mongo = mongoApp.currentUser.mongoClient(MONGODB_DATASOURCE);
      const collection = mongo
        .db(mongoDatabase)
        .collection(MONGODB_COLLECTION_ACTIVEQUEUE);

      // query the Model for documents & update location array & update filtered list
      if (!projectsLoading) {
        setProjectsLoading(true);

        const newList = await collection.find(
          {
            companyCode: companyCode,
            location: locationFilter,
            $and: [
              { classification: { $not: { $eq: "Detail" } } },
              // 2025-03-14 - added Other back in so that new locations that handle their own deliveries can see them { classification: { $not: { $eq: "Other" } } },
            ], // 2025-01-02 - added Detail and Other filter
          },
          {
            projection: {
              id: 1,
              companyCode: 1,
              location: 1,
              invoiceNo: 1,
              surface: 1,
              classification: 1,
              customer_firstName: 1,
              customer_lastName: 1,
              customer_address: 1,
              customer_cellPhone: 1,
              installer: 1,
              lowes_storeNo: 1,
              lowes_projectNo: 1,
              date_scheduled: 1,
              date_estimatedCompletion: 1,
              arrival_time_text: 1,
              statusCode: 1,
              expressShipFlag: 1,
              portalState: 1,
              date_arranged: 1,
              "KellerInteriors::_autoGeneratedModificationTimestamp": 1,
              Materials: 1,
              InvoiceLineItems: 1,
              extraCellId: 1,
              extraCellText: 1,
            },
            // sort: { location: 1 },
            limit: 10000,
          }
        );
        const updatedList = newList.map((document) => {
          // add readable string mongoId to each document
          const stringId = document._id.toString();
          document.mongoId = stringId;
          return document;
        });
        console.log(
          `ModelContext (${mongoDatabase}) getProjectsByCompany documents (${updatedList.length})...`
        );
        // remove duplicate documents
        const processedDocuments =
          processDuplicateProjectDocuments(updatedList);
        console.log(
          `ModelContext (${mongoDatabase}) getProjectsByCompany processedDocuments (${processedDocuments.length}): `,
          processedDocuments
        );
        setProjectsList(processedDocuments);
        updateFilteredProjectsList(processedDocuments, locationFilter);
        setupActiveQueueChangeStream(companyCode, locationFilter);
        setupAttributesChangeStream(companyCode, locationFilter);
        setProjectsLoading(false);
        return processedDocuments;
      }
    }
  };

  // - - - - - - - - - -

  /**
   * Gets candidates by company and location.
   * @param {string} companyCode - The company code.
   * @param {string} locationFilter - The location filter.
   * @returns {Array} - The array of candidate documents.
   */
  const getCandidatesByCompany = async (companyCode, locationFilter) => {
    //
    // - - - - - - - - - -

    const processDuplicateCandidateDocuments = (incomingList) => {
      var duplicateIdList = new Set();
      incomingList.forEach((currentDocument) => {
        const currentId = currentDocument.__ID_Candidate;
        const matchedDocuments = incomingList.filter(
          // create array of matching ids
          (document) => document.__ID_Candidate === currentId
        );
        if (matchedDocuments.length > 1) {
          // duplicate ids found
          matchedDocuments.forEach((duplicatedDocument, index) => {
            if (index > 0) {
              // ignore first in list [0] - that's the first/original occurance; keep it, remove the others
              const duplicatedMongoId = duplicatedDocument._id.toString();
              duplicateIdList.add(duplicatedMongoId);
              const dupIndex = incomingList.findIndex(
                (i) => i._id.toString() === duplicatedMongoId
              );
              if (dupIndex > -1) {
                // only splice array when item is found
                incomingList.splice(dupIndex, 1);
              }
              deleteCandidateDocumentById(duplicatedMongoId);
            }
          });
        }
      });
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") processDuplicateCandidateDocuments, duplicatedCandidateIds: ",
        duplicateIdList
      );
      return incomingList;
    };

    // - - - - - - - - - -

    if (mongoApp.currentUser != null) {
      const mongo = mongoApp.currentUser.mongoClient(MONGODB_DATASOURCE);
      const collection = mongo
        .db(mongoDatabase)
        .collection(MONGODB_COLLECTION_CANDIDATES);

      // query the Model for documents
      if (!candidatesLoading) {
        setCandidatesLoading(true);

        // help with regex query: https://www.mongodb.com/docs/manual/reference/operator/query/regex/
        var newList = await collection.find(
          {
            $or: [
              // 08-09-2024 added Stages filter so that Inactive Candidates are not included
              // 09-25-2024 converted Stages filter to Dashboard_Status filter (JIRA SW-607)
              // 2025-01-08 noted that payrollNo: { $lt: 9000 } is not finding strings (less than 1000.01, like "0142.01")
              //             so having to filter >9000 later, but for now none of them are appearing
              {
                companyCode: companyCode,
                // payrollNo: { $lt: 9000 }, // 2025-01-02 - added payrollNo filter
                Dashboard_Status_Code: 70,
                cLocationCode: locationFilter,
                $and: [
                  {
                    RecordType: { $not: { $regex: "detailer", $options: "i" } },
                  },
                  { RecordType: { $not: { $regex: "helper", $options: "i" } } },
                ], // 2025-01-02 - added Detailer filter
              },
              {
                companyCode: companyCode,
                // payrollNo: { $lt: 9000 }, // 2025-01-02 - added payrollNo filter
                Dashboard_Status_Code: 70,
                Additional_Locations: { $regex: locationFilter, $options: "i" },
                $and: [
                  {
                    RecordType: { $not: { $regex: "detailer", $options: "i" } },
                  },
                  { RecordType: { $not: { $regex: "helper", $options: "i" } } },
                ], // 2025-01-02 - added Detailer filter
              },
            ],
          },
          {
            projection: {
              __ID_Candidate: 1,
              cLocationCode: 1,
              Additional_Locations: 1,
              companyCode: 1,
              Email: 1,
              LowesStoreNo_List: 1,
              Name_Full: 1,
              payrollNo: 1,
              Phone1: 1,
              RecordType: 1,
              Scheduling_Surface: 1,
              Dashboard_Status_Code: 1,
              capacity_carpet_prime_secondary: 1,
              capacity_vinyl_prime_secondary: 1,
              capacity_vinylSheet_prime_secondary: 1,
              capacity_tile_prime_secondary: 1,
              capacity_backsplash_prime_secondary: 1,
              capacity_floating_prime_secondary: 1,
              capacity_glueDown_prime_secondary: 1,
              capacity_nailDown_prime_secondary: 1,
              capacity_carpet_once: 1,
              capacity_vinyl_once: 1,
              capacity_vinylSheet_once: 1,
              capacity_tile_once: 1,
              capacity_backsplash_once: 1,
              capacity_floating_once: 1,
              capacity_glueDown_once: 1,
              capacity_nailDown_once: 1,
              capacity_carpet_once_furniture: 1,
              capacity_vinyl_once_furniture: 1,
              capacity_vinylSheet_once_furniture: 1,
              capacity_tile_once_furniture: 1,
              capacity_backsplash_once_furniture: 1,
              capacity_floating_once_furniture: 1,
              capacity_glueDown_once_furniture: 1,
              capacity_nailDown_once_furniture: 1,
              capacity_carpet_once_steps: 1,
              capacity_vinyl_once_steps: 1,
              capacity_vinylSheet_once_steps: 1,
              capacity_tile_once_steps: 1,
              capacity_backsplash_once_steps: 1,
              capacity_floating_once_steps: 1,
              capacity_glueDown_once_steps: 1,
              capacity_nailDown_once_steps: 1,
              capacity_carpet_twice: 1,
              capacity_vinyl_twice: 1,
              capacity_vinylSheet_twice: 1,
              capacity_tile_twice: 1,
              capacity_backsplash_twice: 1,
              capacity_floating_twice: 1,
              capacity_glueDown_twice: 1,
              capacity_nailDown_twice: 1,
              capacity_carpet_twice_furniture: 1,
              capacity_vinyl_twice_furniture: 1,
              capacity_vinylSheet_twice_furniture: 1,
              capacity_tile_twice_furniture: 1,
              capacity_backsplash_twice_furniture: 1,
              capacity_floating_twice_furniture: 1,
              capacity_glueDown_twice_furniture: 1,
              capacity_nailDown_twice_furniture: 1,
              capacity_carpet_twice_steps: 1,
              capacity_vinyl_twice_steps: 1,
              capacity_vinylSheet_twice_steps: 1,
              capacity_tile_twice_steps: 1,
              capacity_backsplash_twice_steps: 1,
              capacity_floating_twice_steps: 1,
              capacity_glueDown_twice_steps: 1,
              capacity_nailDown_twice_steps: 1,
            },
            // sort: {
            //   location: 1,
            // },
            limit: 5000,
          }
        );

        // 2025-02-13 - flag shared installers (additionalLocations)
        const queryPayrollListArray = [];
        newList.forEach((currentDocument) => {
          const additionalLocations = currentDocument.Additional_Locations;
          // 2025-02-24 - removed !locationFilter from if statement (JIRA SW-683)
          if (additionalLocations) {
            currentDocument.sharedInstallerFlag = true;
            const formattedPayrollNo = dataFormatting_payrollFields(
              currentDocument.payrollNo
            );
            queryPayrollListArray.push(formattedPayrollNo);
            console.log(
              `ModelContext getCandidatesByCompany (${currentDocument.payrollNo}-${currentDocument.cLocationCode}) Additional_Locations: `,
              currentDocument.Additional_Locations
            );
            setSharedDefaultsHandlerFlag(true);
          }
        });
        console.log(
          `ModelContext getCandidatesByCompany queryPayrollListArray: `,
          queryPayrollListArray
        );
        setProjectQueryList(queryPayrollListArray);

        // remove duplicate documents
        const processedDocuments = processDuplicateCandidateDocuments(newList);
        // sort the resulting list
        newList = myJsonArraySort(
          processedDocuments,
          "payrollNo",
          "Number",
          "ascending"
        );
        newList = myJsonArraySort(
          newList,
          "Scheduling_Surface",
          "String/Text",
          "descending"
        );
        setCandidatesList(newList);
        setFilteredCandidatesList(newList);
        setCandidatesLoading(false);
        console.log(
          `ModelContext (${mongoDatabase}) getCandidatesByCompany documents (${newList.length}): `,
          newList
        );
        return newList;
      }
    }
  };

  // - - - - - - - - - -

  /**
   * Gets candidates by a list of payroll numbers.
   * @param {Array} payrollNoArray - The array of payroll numbers.
   * @returns {Array} - The array of candidate documents.
   */
  const getCandidatesByPayrollNoList = async (payrollNoArray) => {
    if (mongoApp.currentUser != null) {
      setCrewQueryLoading(true);
      var compiledQuery = [];
      var newObject = {};
      // DEV NOTE: this currently causes 1451.01 Detailer to be found even if Detailer filtering is OFF
      payrollNoArray.forEach((currentPayrollNo, index) => {
        // 2025-01-08 - changed parsefloat(payrollNo) to payrollNo so that it searches for payrollNo strings like "0142.01"
        // 2025-01-15 - added parsefloat(payrollNo) back into payrollNo so that it searches for payrollNo numbers also like 3037.01
        newObject = {
          payrollNo: currentPayrollNo,
          companyCode: companyCode,
        };
        compiledQuery.push(newObject);
        newObject = {
          payrollNo: parseFloat(currentPayrollNo),
          companyCode: companyCode,
        };
        compiledQuery.push(newObject);
      });
      console.log(
        `ModelContext (${mongoDatabase}) getCandidatesByPayrollNoList compiledQuery: `,
        compiledQuery
      );

      const mongo = mongoApp.currentUser.mongoClient(MONGODB_DATASOURCE);
      const collection = mongo
        .db(mongoDatabase)
        .collection(MONGODB_COLLECTION_CANDIDATES);

      // query the Model for document
      var newDocList = await collection.find(
        { $or: compiledQuery },
        {
          projection: {
            __ID_Candidate: 1,
            cLocationCode: 1,
            Additional_Locations: 1,
            companyCode: 1,
            Dashboard_Status_Code: 1,
            Email: 1,
            LowesStoreNo_List: 1,
            Name_Full: 1,
            payrollNo: 1,
            Phone1: 1,
            RecordType: 1,
            Scheduling_Surface: 1,
          },
          limit: 100,
        }
      );
      console.log(
        `ModelContext (${mongoDatabase}) getCandidatesByPayrollNoList documents (${newDocList.length}): `,
        newDocList
      );
      setRequestedCandidatesList(newDocList);
      setCrewQueryLoading(false);
      return newDocList;
    }
  };

  // - - - - - - - - - -

  /**
   * Gets projects by a list of payroll numbers.
   * @param {Array} payrollNoArray - The array of payroll numbers.
   * @returns {Array} - The array of project documents.
   */
  const getProjectsByPayrollNoList = async (payrollNoArray) => {
    if (mongoApp.currentUser != null) {
      setProjectQueryLoading(true);
      var compiledQuery = [];
      var newObject = {};
      payrollNoArray.forEach((currentPayrollNo, index) => {
        newObject = {
          installer: currentPayrollNo,
          companyCode: companyCode,
          location: { $not: { $eq: locationFilter } },
          $and: [
            { classification: { $not: { $eq: "Detail" } } },
            // 2025-03-14 - added Other back in so that new locations that handle their own deliveries can see them { classification: { $not: { $eq: "Other" } } },
          ],
        };
        compiledQuery.push(newObject);
      });
      console.log(
        `ModelContext (${mongoDatabase}) getProjectsByPayrollNoList compiledQuery: `,
        compiledQuery
      );
      // set the queryList empty to prevent an infinite loop
      setProjectQueryList([]);

      const mongo = mongoApp.currentUser.mongoClient(MONGODB_DATASOURCE);
      const collection = mongo
        .db(mongoDatabase)
        .collection(MONGODB_COLLECTION_ACTIVEQUEUE);

      // query the Model for documents
      var newDocList = await collection.find(
        { $or: compiledQuery },
        {
          projection: {
            id: 1,
            companyCode: 1,
            location: 1,
            invoiceNo: 1,
            surface: 1,
            classification: 1,
            customer_firstName: 1,
            customer_lastName: 1,
            customer_address: 1,
            customer_cellPhone: 1,
            installer: 1,
            lowes_storeNo: 1,
            lowes_projectNo: 1,
            date_scheduled: 1,
            date_estimatedCompletion: 1,
            arrival_time_text: 1,
            statusCode: 1,
            expressShipFlag: 1,
            portalState: 1,
            date_arranged: 1,
            "KellerInteriors::_autoGeneratedModificationTimestamp": 1,
            Materials: 1,
            InvoiceLineItems: 1,
            extraCellId: 1,
            extraCellText: 1,
          },
          limit: 10000,
        }
      );
      setRequestedProjectsList(newDocList);
      setProjectQueryLoading(false);
      console.log(
        `ModelContext (${mongoDatabase}) getProjectsByPayrollNoList documents (${newDocList.length}): `,
        newDocList
      );
      return newDocList;
    }
  };

  // - - - - - - - - - -

  /**
   * Gets attributes by location.
   * @param {string} companyCode - The company code.
   * @param {string} locationFilter - The location filter.
   * @returns {Array} - The array of attribute documents.
   */
  const getAttributesByLocation = async (companyCode, locationFilter) => {
    if (mongoApp.currentUser != null) {
      const mongo = mongoApp.currentUser.mongoClient(MONGODB_DATASOURCE);
      const collection = mongo
        .db(mongoDatabase)
        .collection(MONGODB_COLLECTION_ATTRIBUTES);

      // query the Model for documents
      if (!attributesLoading) {
        setAttributesLoading(true);

        // help with regex query: https://www.mongodb.com/docs/manual/reference/operator/query/regex/
        var newList = await collection.find(
          {
            companyCode: companyCode,
            location: locationFilter,
            deleted: { $ne: true },
          },
          {
            projection: {
              id: 1,
              location: 1,
              companyCode: 1,
              date: 1,
              _kf_payrollNo: 1,
              invoiceNo: 1,
              distant: 1,
              expedite: 1,
              locked: 1,
              encodedSmallTitle: 1,
              encodedLargeTitle: 1,
              availability: 1,
              extra: 1,
            },
            limit: 10000,
          }
          // DEV NOTE: this 10k limit will eventually cause all new attributes to become missing !! Archiving discussion?
        );
        console.log(
          `ModelContext (${mongoDatabase}) getAttributesByLocation documents (${newList.length}): `,
          newList
        );
        setAttributesList(newList);
        setAttributesLoading(false);
        return newList;
      }
    }
  };

  // - - - - - - - - - -

  /**
   * Creates a new user record.
   * @returns {Object} - The new user record.
   */
  const createNewUser = async () => {
    // create a new User record with default values

    //   note: deep copy so that nested objects are copied as well instead of referenced
    const newUserRecord = await JSON.parse(
      JSON.stringify(defaultNewUserRecord)
    );
    newUserRecord.id = mongoUser?.id;
    newUserRecord.username = mongoUser?._profile.data.email;
    console.log(
      "ModelContext (" + mongoDatabase + ") createNewUser newUserRecord: ",
      newUserRecord
    );
    //
    // - - - - -

    const handleCreateNewUserError = (err) => {
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") createNewUser handleCreateNewUserError (alert to user 'New user record error!'): ",
        err
      );
      alert("New user record error!");
    };

    // - - - - -

    if (mongoApp.currentUser != null) {
      const mongo = mongoApp.currentUser.mongoClient(MONGODB_DATASOURCE);
      const collection = mongo
        .db(mongoDatabase)
        .collection(MONGODB_COLLECTION_USERS);

      const returnedRecord = await collection
        .insertOne(newUserRecord)
        .catch(handleCreateNewUserError);
      console.log(
        "ModelContext (" + mongoDatabase + ") createNewUser: ",
        returnedRecord
      );
      // now trigger the getUser function to get the full record just created
      getUserById(mongoUser.id);
      return returnedRecord;
    }
  };

  // - - - - - - - - - -

  /**
   * Creates a new attribute record.
   * @param {Object} newAttribute - The new attribute object.
   * @returns {Object} - The new attribute record.
   */
  const createNewAttribute = async (newAttribute) => {
    // create a new Attribute record
    //
    // - - - - -

    const handleCreateNewAttributeError = (err) => {
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") createNewUser handleCreateNewAttributeError (alert to user 'New attribute record error!'): ",
        err
      );
      alert("New attribute record error!");
    };

    // - - - - -

    if (mongoApp.currentUser != null) {
      const mongo = mongoApp.currentUser.mongoClient(MONGODB_DATASOURCE);
      const collection = mongo
        .db(mongoDatabase)
        .collection(MONGODB_COLLECTION_ATTRIBUTES);

      const returnedRecord = await collection
        .insertOne(newAttribute)
        .catch(handleCreateNewAttributeError);
      console.log(
        "ModelContext createNewAttribute: ",
        returnedRecord,
        ", id: ",
        returnedRecord.insertedId.toString()
      );
      const newAttributeObject = {
        originalId: newAttribute.id,
        mongoId: returnedRecord.insertedId.toString(),
      };
      setNewAttributeList([...newAttributeList, newAttributeObject]);
      return returnedRecord;
    }
  };

  // - - - - - - - - - -

  /**
   * Sets user preferences by ID.
   * @param {string} userId - The user ID.
   * @returns {Object} - The updated user record.
   */
  const setUserPrefsById = async (userId) => {
    // user preference has changed so edit/set/update the Mongo job document
    //
    // - - - - -

    const handleSetUserPrefsError = (err) => {
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") setUserPrefsById handleSetUserPrefsError (alert to user 'Update User record error!'): ",
        err
      );
      alert("Update User record error!");
    };

    // - - - - -

    if (mongoApp.currentUser != null) {
      const mongo = mongoApp.currentUser.mongoClient(MONGODB_DATASOURCE);
      const collection = mongo
        .db(mongoDatabase)
        .collection(MONGODB_COLLECTION_USERS);

      const returnedRecord = await collection
        .updateOne(
          {
            id: userId,
          },
          {
            $set: {
              defaultUImode: darkMode ? "dark" : "light",
              defaultCompany: companyCode,
              defaultLocation: locationFilter,
              defaultDate: weekDatesArray[0],
              defaultTab: whichList,
              defaultFilters: currentFilters,
              defaultCountsToggle: countsToggle,
              jobTitleConfig: jobTitleConfig,
            },
          }
        )
        .catch(handleSetUserPrefsError);
      console.log(
        "ModelContext (" + mongoDatabase + ") setUserPrefsById(",
        userId,
        "): ",
        returnedRecord,
        "; jobTitleConfig: ",
        jobTitleConfig
      );
      return returnedRecord;
    }
  };

  // - - - - - - - - - -

  /**
   * Sets the schedule for a job by ID.
   * @param {string} id - The job ID.
   * @param {string} payrollNo - The payroll number.
   * @param {string} scheduledDate - The scheduled date.
   * @param {string} estimatedCompletionDate - The estimated completion date.
   * @param {string} arrivalTime - The arrival time.
   * @param {number} statusCode - The status code.
   * @param {string} extraCellId - The extra cell ID.
   * @param {string} extraCellText - The extra cell text.
   * @returns {Object} - The updated job record.
   */
  const setScheduleById = async (
    id,
    payrollNo,
    scheduledDate,
    estimatedCompletionDate,
    arrivalTime,
    statusCode,
    extraCellId,
    extraCellText
  ) => {
    // job has now been scheduled so edit/set/update the Mongo job document
    //
    // - - - - -

    const handleSetScheduleError = (err) => {
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") setScheduleById handleSetScheduleError (alert to user 'Update record error!'): ",
        err
      );
      alert("Update record error!");
    };

    // - - - - -

    if (mongoApp.currentUser != null) {
      const history = new Date()
        .toString()
        .concat(" - ")
        .concat(mongoUser._profile.data.email)
        .concat(", tab:")
        .concat(whichList);
      const mongo = mongoApp.currentUser.mongoClient(MONGODB_DATASOURCE);
      const collection = mongo
        .db(mongoDatabase)
        .collection(MONGODB_COLLECTION_ACTIVEQUEUE);

      const returnedRecord = await collection
        .updateOne(
          {
            _id: ObjectId(id),
          },
          {
            $set: {
              installer: payrollNo,
              date_scheduled: scheduledDate,
              date_estimatedCompletion: estimatedCompletionDate,
              arrival_time_text: arrivalTime,
              statusCode: statusCode,
              extraCellId: extraCellId,
              extraCellText: extraCellText,
              history: history,
            },
          }
        )
        .catch(handleSetScheduleError);
      console.log(
        "ModelContext (" + mongoDatabase + ") setScheduleById: ",
        returnedRecord,
        ", payrollNo: ",
        payrollNo,
        ", scheduledDate: ",
        scheduledDate,
        ", arrivalTime: ",
        arrivalTime,
        ", estimatedCompletionDate: ",
        estimatedCompletionDate,
        ", statusCode: ",
        statusCode,
        ", history: ",
        history
      );
      return returnedRecord;
    }
  };

  // - - - - - - - - - -

  /**
   * Sets the suppression status for a job by ID.
   * @param {string} id - The job ID.
   * @param {string} suppressedDate - The suppressed date.
   * @param {string} suppressedNote - The suppressed note.
   * @param {number} suppressedStatus - The suppressed status code.
   * @returns {Object} - The updated job record.
   */
  const setSuppressionById = async (
    id,
    suppressedDate,
    suppressedNote,
    suppressedStatus
  ) => {
    // job has now been suppressed so edit/set/update the Mongo job document
    //
    // - - - - -

    const handleSetSuppressionError = (err) => {
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") setSuppressionById handleSetSuppressionError (alert to user 'Update record error!'): ",
        err
      );
      alert("Update record error!");
    };

    // - - - - -

    if (mongoApp.currentUser != null) {
      const mongo = mongoApp.currentUser.mongoClient(MONGODB_DATASOURCE);
      const collection = mongo
        .db(mongoDatabase)
        .collection(MONGODB_COLLECTION_ACTIVEQUEUE);

      const returnedRecord = await collection
        .updateOne(
          {
            _id: ObjectId(id),
          },
          {
            $set: {
              statusCode: suppressedStatus,
              date_suppressed: suppressedDate,
              FileMakerSuppressNotes: suppressedNote,
            },
          }
        )
        .catch(handleSetSuppressionError);
      console.log(
        "ModelContext (" + mongoDatabase + ") setSuppressionById: ",
        returnedRecord,
        ", suppressedDate: ",
        suppressedDate,
        ", suppressedNote: ",
        suppressedNote
      );
      return returnedRecord;
    }
  };

  // - - - - - - - - - -

  /**
   * Sets an attribute by ID.
   * @param {string} id - The attribute ID.
   * @param {Object} newAttribute - The new attribute object.
   * @returns {Object} - The updated attribute record.
   */
  const setAttributeById = async (id, newAttribute) => {
    // attribute has now been changed so edit/set/update the Mongo job document
    //
    // - - - - -

    const handleSetAttributeError = (err) => {
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") setAttributeById handleSetAttributeError (alert to user 'Update record error!'): ",
        err
      );
      alert("Update record error!");
    };

    // - - - - -

    if (mongoApp.currentUser != null) {
      const mongo = mongoApp.currentUser.mongoClient(MONGODB_DATASOURCE);
      const collection = mongo
        .db(mongoDatabase)
        .collection(MONGODB_COLLECTION_ATTRIBUTES);

      const returnedRecord = await collection
        .updateOne(
          {
            _id: ObjectId(id),
          },
          {
            $set: {
              _kf_payrollNo: newAttribute._kf_payrollNo,
              date: newAttribute.date,
              encodedSmallTitle: newAttribute.encodedSmallTitle,
              encodedLargeTitle: newAttribute.encodedLargeTitle,
              invoiceNo: newAttribute.invoiceNo,
              distant: newAttribute.distant,
              expedite: newAttribute.expedite,
              locked: newAttribute.locked,
              availability: newAttribute.availability,
              extra: newAttribute.extra,
              deleted: newAttribute.deleted,
            },
          }
        )
        .catch(handleSetAttributeError);
      console.log(
        "ModelContext (" + mongoDatabase + ") setAttributeById: ",
        returnedRecord
      );
      return returnedRecord;
    }
  };

  // - - - - - - - - - -

  /**
   * Deletes an active queue document by ID.
   * @param {string} id - The document ID.
   * @returns {Object} - The deleted document record.
   */
  const deleteAQDocumentById = async (id) => {
    //
    // - - - - -

    const handleDeleteAQDocumentError = (err) => {
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") deleteAQDocumentById handleDeleteAQDocumentError: ",
        err
      );
    };

    // - - - - -

    if (mongoApp.currentUser != null) {
      const mongo = mongoApp.currentUser.mongoClient(MONGODB_DATASOURCE);
      const collection = mongo
        .db(mongoDatabase)
        .collection(MONGODB_COLLECTION_ACTIVEQUEUE);

      const returnedRecord = await collection
        .deleteOne({
          _id: ObjectId(id),
        })
        .catch(handleDeleteAQDocumentError);
      console.log(
        "ModelContext (" + mongoDatabase + ") deleteAQDocumentById: ",
        returnedRecord
      );
      return returnedRecord;
    }
  };

  // - - - - - - - - - -

  /**
   * Deletes a candidate document by ID.
   * @param {string} id - The document ID.
   * @returns {Object} - The deleted document record.
   */
  const deleteCandidateDocumentById = async (id) => {
    //
    // - - - - -

    const handleDeleteCandidateDocumentError = (err) => {
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") deleteCandidateDocumentById handleDeleteCandidateDocumentError: ",
        err
      );
    };

    // - - - - -

    if (mongoApp.currentUser != null) {
      const mongo = mongoApp.currentUser.mongoClient(MONGODB_DATASOURCE);
      const collection = mongo
        .db(mongoDatabase)
        .collection(MONGODB_COLLECTION_CANDIDATES);

      const returnedRecord = await collection
        .deleteOne({
          _id: ObjectId(id),
        })
        .catch(handleDeleteCandidateDocumentError);
      console.log(
        "ModelContext (" + mongoDatabase + ") deleteCandidateDocumentById: ",
        returnedRecord
      );
      return returnedRecord;
    }
  };

  // - - - - - - - - - -

  /**
   * Deletes an attribute document by ID.
   * @param {string} id - The document ID.
   * @returns {Object} - The deleted document record.
   */
  const deleteAttributeDocumentById = async (id) => {
    //
    // - - - - -

    const handleDeleteAttributeDocumentError = (err) => {
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") deleteAttributeDocumentById handleDeleteAttributeDocumentError: ",
        err
      );
    };

    // - - - - -

    if (mongoApp.currentUser != null) {
      const mongo = mongoApp.currentUser.mongoClient(MONGODB_DATASOURCE);
      const collection = mongo
        .db(mongoDatabase)
        .collection(MONGODB_COLLECTION_ATTRIBUTES);

      const returnedRecord = await collection
        .deleteOne({
          _id: ObjectId(id),
        })
        .catch(handleDeleteAttributeDocumentError);
      console.log(
        "ModelContext (" + mongoDatabase + ") deleteAttributeDocumentById: ",
        returnedRecord
      );
      return returnedRecord;
    }
  };

  // - - - - - - - - - - - - - - - - -  EFFECT HOOKS  - - - - - - - - - - - - - - - - - -
  //
  // networkErrorFlag change               -> setupChangeStreams
  // events change                         -> processChangeEvents
  // instance change                       -> setup Msal
  // mongoUser change                      -> getUserById
  // userDocument change                   -> setCompanyCode
  // jobTitleConfig change                 -> setUserPrefsById
  // currentFilters change                 -> setUserPrefsById
  // countsToggle change                   -> setUserPrefsById
  // requestedCandidatesList change        -> setCandidatesList
  // requestedProjectsList change          -> setProjectsList
  // crewQueryList change                  -> getCandidatesByPayrollNoList
  // projectQueryList change               -> getProjectsByPayrollNoList
  // newAttributeList change               -> updateAttributeIds
  //

  useEffect(() => {
    if (networkErrorFlag) {
      // networkErrorFlag has been set, so re-setup change stream
      setNetworkErrorFlag(false);
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") useEffect[networkErrorFlag]...(setupActiveQueueChangeStream)"
      );
      setupActiveQueueChangeStream(companyCode, locationFilter);
      setupAttributesChangeStream(companyCode, locationFilter);
    }
  }, [networkErrorFlag]);

  // - - - - - - - - - -

  useEffect(() => {
    if (events.length > 0) {
      // changedEvent has been set, so process the change
      processChangeEvents(events);
      setEvents([]);
    }
  }, [events]);

  // - - - - - - - - - -

  useEffect(() => {
    // This will be run on component mount
    instance.enableAccountStorageEvents();
    const callbackId = instance.addEventCallback((message) => {
      // This will be run every time an event is emitted after registering this callback
      if (message.eventType === EventType.LOGIN_SUCCESS && message.payload) {
        const account = message.payload;
        instance.setActiveAccount(account);
      } else if (
        message.eventType === EventType.ACTIVE_ACCOUNT_CHANGED &&
        message.payload
      ) {
        const account = message.payload;
        instance.setActiveAccount(account);
      }
    });

    return () => {
      // This will be run on component unmount
      instance.disableAccountStorageEvents();
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  // - - - - - - - - - -

  useEffect(() => {
    //
    // NOTE: this effect appears to trigger before msalLogin/Jwt creation when a user is already logged in account (cookie I presume)
    //
    if (mongoUser && !userLoading) {
      setUserLoading(true);
      // user has been set, so request User
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") useEffect[mongoUser]...(request User)"
      );
      getUserById(mongoUser.id);
    }
  }, [mongoUser]);

  // - - - - - - - - - -

  useEffect(() => {
    if (userDocument.id) {
      // userDocument has been set, so process the change
      // console.log("ModelContext (" + mongoDatabase + ") useEffect[userDocument]...(setCompanyCode)");
      setupUserSettings();
    } else {
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") useEffect[userDocument], no userDoc.id, id: ",
        userDocument?.id
      );
    }
  }, [userDocument]);

  // - - - - - - - - - -

  useEffect(() => {
    if (mongoUser) {
      // prefs has been changed, so process the change
      setUserPrefsById(mongoUser.id);
    }
  }, [currentFilters, countsToggle, jobTitleConfig, weekDatesArray, whichList]);

  // - - - - - - - - - -

  useEffect(() => {
    if (mongoUser) {
      // requestedCandidatesList has been changed, so process the change
      // NOTE: concatenating multiple arrays: https://stackoverflow.com/questions/5080028/what-is-the-most-efficient-way-to-concatenate-n-arrays
      var newCandidatesList = [...candidatesList, ...requestedCandidatesList];
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") useEffect[requestedCandidatesList] newCandidatesList: ",
        newCandidatesList
      );
      // sort the resulting list
      newCandidatesList = myJsonArraySort(
        newCandidatesList,
        "payrollNo",
        "Number",
        "ascending"
      );
      newCandidatesList = myJsonArraySort(
        newCandidatesList,
        "Scheduling_Surface",
        "String/Text",
        "descending"
      );
      setCandidatesList(newCandidatesList);
      setFilteredCandidatesList(newCandidatesList);
    }
  }, [requestedCandidatesList]);

  // - - - - - - - - - -

  useEffect(() => {
    if (mongoUser) {
      if (
        !projectsLoading &&
        !projectQueryLoading &&
        projectsList.length > 0 &&
        requestedProjectsList.length > 0
      ) {
        // requestedProjectsList has been changed, so process the change
        var newProjectsList = [...projectsList, ...requestedProjectsList];
        console.log(
          `ModelContext (${mongoDatabase}) useEffect[requestedProjectsList(${requestedProjectsList.length})] new combined ProjectsList(${newProjectsList.length}): `,
          newProjectsList
        );
        setProjectsList(newProjectsList);
        setRequestedProjectsList([]);
        updateFilteredProjectsList(newProjectsList, locationFilter);
      }
    }
  }, [requestedProjectsList, projectsList]);

  // - - - - - - - - - -

  useEffect(() => {
    if (sharedDefaultsHandlerFlag) {
      if (!attributesLoading && attributesList.length > 0) {
        // attributes are loaded, so handle defaultAttributes
        setSharedDefaultsHandlerFlag(false);
        processSharedInstallerAttributeDefaults();
        console.log(
          `ModelContext (${mongoDatabase}) useEffect[sharedDefaultsHandlerFlag...] handle defaultAttributes`
        );
      }
    }
  }, [sharedDefaultsHandlerFlag, attributesList, attributesLoading]);

  // - - - - - - - - - -

  useEffect(() => {
    if (mongoUser && crewQueryList.length > 0) {
      // crewQueryList has been changed, so process the change
      getCandidatesByPayrollNoList(crewQueryList);
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") useEffect[crewQueryList]...(getCandidatesByPayrollNoList)"
      );
    }
  }, [crewQueryList]);

  // - - - - - - - - - -

  useEffect(() => {
    if (mongoUser && projectQueryList.length > 0 && projectsList.length > 0) {
      // projectQueryList has been changed & projectList is ready, so process the change
      getProjectsByPayrollNoList(projectQueryList);
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") useEffect[projectQueryList]...(getProjectsByPayrollNoList)"
      );
    } else {
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") useEffect[projectQueryList]...ignoring (empty list)"
      );
    }
  }, [projectQueryList, projectsList]);

  // - - - - - - - - - -

  useEffect(() => {
    if (newAttributeList.length > 0) {
      // newAttributeList has been changed, so process the change
      updateAttributeIds();
      console.log(
        "ModelContext (" +
          mongoDatabase +
          ") useEffect[newAttributeList] newAttributeList: ",
        newAttributeList
      );
    }
  }, [newAttributeList]);

  // - - - - - - - - - - - - - - - - -  CONTEXT RETURN  - - - - - - - - - - - - - - - - - -

  return (
    <ModelContext.Provider
      value={{
        mongoApp,
        mongoDatabase,
        mongoUser,
        events,
        projectsList,
        candidatesList,
        requestedCandidatesList,
        requestedProjectsList,
        attributesList,
        userDocument,
        filteredProjectsList,
        filteredCandidatesList,
        newAttributeList,
        projectsLoading,
        candidatesLoading,
        crewsLoading,
        attributesLoading,
        locationsLoading,
        userLoading,
        crewQueryList,
        projectQueryList,
        crewQueryLoading,
        projectQueryLoading,
        sharedDefaultsHandlerFlag,
        setMongoApp,
        setMongoDatabase,
        setMongoUser,
        setEvents,
        setProjectsList,
        setCandidatesList,
        setRequestedCandidatesList,
        setRequestedProjectsList,
        setAttributesList,
        setFilteredProjectsList,
        setNewAttributeList,
        setProjectsLoading,
        setCandidatesLoading,
        setCrewsLoading,
        setAttributesLoading,
        setUserDocument,
        setLocationsLoading,
        setUserLoading,
        setCrewQueryList,
        setProjectQueryList,
        setCrewQueryLoading,
        setProjectQueryLoading,
        setSharedDefaultsHandlerFlag,
        myJsonArraySort,
        processBatchAttributes,
        processSharedInstallerAttributeDefaults,
        insertActiveQueueDocument,
        insertAttributeDocument,
        replaceActiveQueueDocument,
        replaceAttributeDocument,
        deleteActiveQueueDocument,
        deleteAttributeDocument,
        processChangeEvents,
        setupUserSettings,
        setupActiveQueueChangeStream,
        setupAttributesChangeStream,
        updateAttributeIds,
        updateAttributeList,
        updateUnassignedAttributeList,
        updateFilteredCandidatesList,
        updateFilteredProjectsList,
        updateFilteredLists,
        createJobAttribute,
        createExtraCellAttribute,
        createCrewDefaultsAttribute,
        createCrewQueryList,
        removeExtraCellAttribute,
        handleLoginError,
        loginMsal,
        logoutUser,
        getUniqueLocations,
        getUserById,
        getProjectById,
        getProjectsByCompany,
        getCandidatesByCompany,
        getCandidatesByPayrollNoList,
        getAttributesByLocation,
        createNewUser,
        createNewAttribute,
        setUserPrefsById,
        setScheduleById,
        setSuppressionById,
        setAttributeById,
        deleteAQDocumentById,
        deleteCandidateDocumentById,
        deleteAttributeDocumentById,
      }}
    >
      {children}
    </ModelContext.Provider>
  );
};
