import { useEffect, useContext, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { EventType, InteractionStatus } from "@azure/msal-browser";
import { AppContext } from "./context/app.context";
import { ScheduleContext } from "./context/schedule.context";
import { ModelContext } from "./context/model.context";
import { ProjectListScreen } from "./screens/projectListScreen";
import { ScheduleScreen } from "./screens/scheduleScreen";
import { OptimizerScreen } from "./screens/optimizerScreen";
import "./App.css";
import Header from "./components/Header";
import JobsDetailModal from "./screens/schedule/JobsDetailModal";
import { createPortal } from "react-dom";
import CrewDetailModal from "./screens/schedule/CrewDetailModal";
import UnassignedDetailModal from "./screens/schedule/UnassignedDetailModal";
import ExtraCellAddModal from "./screens/schedule/ExtraCellAddModal";
import ExtraCellDetailModal from "./screens/schedule/ExtraCellDetailModal";
import JobSettingsModal from "./screens/schedule/JobSettingsModal";
import SuppressModal from "./screens/schedule/SuppressModal";
import JobInitModal from "./screens/schedule/JobInitModal";
import msLogo from "./assets/ms-logo.png";

//
//    npm start
//    npm run build:uat
//    aws s3 sync build/ s3://scheduler-uat.kellerinteriors.com
//    npm run build:production
//    aws s3 sync build/ s3://scheduler.kellerinteriors.com
//

// Microsoft 360 Azure Entra ActiveDirectory Login Authentication
// @azure/msal-react - github: https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-react
//                     docs: https://learn.microsoft.com/en-us/entra/identity-platform/scenario-spa-app-registration

const App = () => {
  const { instance, accounts, inProgress } = useMsal();
  const [popupWindow, setPopupWindow] = useState(null);
  const {
    locationFilter,
    currentFilters,
    whichList,
    openJobsDetailModal,
    openCrewDetailModal,
    openUnassignedDetailModal,
    openExtraCellAddModal,
    openExtraCellDetailModal,
    openJobSettingsModal,
    openSuppressModal,
    openJobInitModal,
    setOpenJobSettingsModal,
    setOpenUnassignedDetailModal,
    setOpenJobsDetailModal,
    setOpenCrewDetailModal,
    setOpenExtraCellAddModal,
    setOpenExtraCellDetailModal,
    setOpenSuppressModal,
    setOpenJobInitModal,
  } = useContext(AppContext);
  const {
    mongoUser,
    userLoading,
    updateFilteredLists,
    projectsList,
    candidatesList,
    locationsLoading,
    candidatesLoading,
    projectsLoading,
    attributesLoading,
    loginMsal,
    logoutUser,
  } = useContext(ModelContext);
  const { schedulePreparing } = useContext(ScheduleContext);

  // - - - - - - - - - -

  const onLoginClick = () => {
    console.log("App onLoginClick...");
    const callbackId = instance.addEventCallback((message) => {
      if (message.eventType === EventType.POPUP_OPENED) {
        // Save the popup window for focusing later
        setPopupWindow(message.payload.popupWindow);
      }
    });

    // If the popup window is already open, focus it
    if (popupWindow && !popupWindow.closed && popupWindow.focus) {
      popupWindow.focus();
    } else {
      const handleLoginError = (err) => {
        console.log(
          "App onLoginClick handleLoginError (alert to user 'Login failed'): ",
          err
        );
        alert("Login failed!");
      };
      instance
        .loginPopup()
        .catch((err) => handleLoginError(err))
        .finally(() => {
          setPopupWindow(null);
          instance.removeEventCallback(callbackId);
        });
    }
  };

  // - - - - - - - - - - - - - - - - -  EFFECT HOOKS  - - - - - - - - - - - - - - - - - -
  //
  // currentFilters change         -> updateFilteredLists(modelContext)
  // msal changes                  -> loginMsal(modelContext)
  //

  // - - - - - - - - - -

  useEffect(() => {
    // currentFilters has changed (select type filter)
    console.log("App useEffect[currentFilters]...(updateFilteredLists)");
    updateFilteredLists(candidatesList, projectsList, locationFilter);
  }, [currentFilters]);

  // - - - - - - - - - -

  useEffect(() => {
    const account = instance.getActiveAccount();

    console.log(
      "App useEffect[instance, inProgress, mongoUser, accounts, loginMsal, logoutUser] inProgress: ",
      inProgress
    );
    const handleTokenError = (err) => {
      console.log(
        "App useEffect[instance, inProgress, mongoUser, accounts, loginMsal, logoutUser] error (alert to user 'Login failed'): ",
        err
      );
      alert("Login failed!");
    };
    if (account && !mongoUser && inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent({
          scopes: ["4ae8c1ac-41df-46bb-a881-011a10d1b09b/.default"],
          account: account,
        })
        .then((response) => {
          if (response) {
            loginMsal(response.accessToken);
          }
        })
        .catch((err) => handleTokenError(err));
    } else if (!account) {
      logoutUser();
    }
  }, [instance, inProgress, mongoUser, accounts, loginMsal, logoutUser]);

  // - - - - - - - - - - - - - - - - -  APP RETURN  - - - - - - - - - - - - - - - - - -

  return (
    <div className="flex flex-col h-screen text-skin-primary bg-skin-base">
      <Header />
      {!mongoUser ? (
        <div className="h-full bg-login-bg bg-center flex justify-center items-center bg-cover">
          {inProgress !== InteractionStatus.Startup &&
          inProgress !== InteractionStatus.HandleRedirect &&
          !userLoading ? (
            <div className="p-8 bg-skin-primary shadow-4 flex flex-col items-center gap-4">
              <span className="text-2xl font-normal">
                Welcome to the Jobs Scheduler
              </span>
              <div className="text-sm font-normal flex flex-col items-center">
                <div>Please use your Keller Interiors or SCG Surfaces</div>
                <div>account to log in</div>
              </div>
              <button
                className="flex items-center gap-2 p-2 shadow-4 rounded-lg bg-skin-primary-inverted"
                onClick={() => onLoginClick()}
              >
                <img src={msLogo} alt="Microsoft" />
                <span className="text-skin-primary-inverted text-sm font-bold">
                  Sign In with Microsoft
                </span>
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <main className="main-container">
          {locationsLoading ||
          candidatesLoading ||
          projectsLoading ||
          attributesLoading ||
          schedulePreparing ? (
            <div className="loader"></div>
          ) : mongoUser ? (
            whichList === "list" ? (
              <ProjectListScreen></ProjectListScreen>
            ) : whichList === "optimizer" ? (
              <OptimizerScreen></OptimizerScreen>
            ) : (
              <ScheduleScreen></ScheduleScreen>
            )
          ) : (
            ""
          )}
        </main>
      )}

      {openJobsDetailModal.show
        ? createPortal(
            <JobsDetailModal
              jobsDetail={openJobsDetailModal.jobsDetail}
              setOpenJobsDetailModal={setOpenJobsDetailModal}
            ></JobsDetailModal>,
            document.body
          )
        : ""}
      {openUnassignedDetailModal.show
        ? createPortal(
            <UnassignedDetailModal
              setOpenUnassignedDetailModal={setOpenUnassignedDetailModal}
            ></UnassignedDetailModal>,
            document.body
          )
        : ""}
      {openExtraCellAddModal.show
        ? createPortal(
            <ExtraCellAddModal
              jobsDetail={openExtraCellAddModal.jobsDetail}
              setOpenExtraCellAddModal={setOpenExtraCellAddModal}
            ></ExtraCellAddModal>,
            document.body
          )
        : ""}
      {openExtraCellDetailModal.show
        ? createPortal(
            <ExtraCellDetailModal
              jobsDetail={openExtraCellDetailModal.jobsDetail}
              setOpenExtraCellDetailModal={setOpenExtraCellDetailModal}
            ></ExtraCellDetailModal>,
            document.body
          )
        : ""}
      {openCrewDetailModal.show
        ? createPortal(
            <CrewDetailModal
              crewDetail={openCrewDetailModal.crewDetail}
              setOpenCrewDetailModal={setOpenCrewDetailModal}
            ></CrewDetailModal>,
            document.body
          )
        : ""}
      {openJobSettingsModal.show
        ? createPortal(
            <JobSettingsModal
              crewDetail={openJobSettingsModal.crewDetail}
              setOpenJobSettingsModal={setOpenJobSettingsModal}
            ></JobSettingsModal>,
            document.body
          )
        : ""}
      {openJobInitModal.show
        ? createPortal(
            <JobInitModal
              currentJob={openJobInitModal.currentJob}
              suppress={openJobInitModal.suppress}
              setOpenJobInitModal={setOpenJobInitModal}
            ></JobInitModal>,
            document.body
          )
        : ""}
      {openSuppressModal.show
        ? createPortal(
            <SuppressModal
              currentJob={openSuppressModal.currentJob}
              suppress={openSuppressModal.suppress}
              setOpenSuppressModal={setOpenSuppressModal}
            ></SuppressModal>,
            document.body
          )
        : ""}
    </div>
  );
};

export default App;
